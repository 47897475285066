import React, { useCallback } from "react";
import { format } from "date-fns";
import { use100vh } from "react-div-100vh";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Button } from "atoms/Button/Button";
import Attachment from "assets/attachment.svg";
import Notes from "assets/notes.svg";

import "./Sidebar.css";
import MemoLeftArrowIcon from "assets/icons/LeftArrowIcon";
import MemoSidebarPlane from "assets/icons/SidebarPlane";
import MemoItineraryModalHeader from "assets/icons/ItineraryModalHeader";

export const ItinerarySidebar = ({
  tripUrl,
  destinations,
  onEdit,
  setNewDestination,
  collapsed,
  setCollapsed,
  onGenerate,
  isDone,
  setItineraryToDelete,
  deleteModalOpen,
}) => {
  const onDestinationEdit = useCallback(
    (destination) => () => {
      if (typeof onEdit === "function") {
        onEdit(destination);
      }
    },
    [onEdit]
  );

  const onCollapseToggle = useCallback(
    () => setCollapsed((prev) => !prev),
    [setCollapsed]
  );

  const height = use100vh();

  return (
    <div
      className={`sidebar-container${collapsed ? " sidebar-collapsed" : ""}`}
    >
      <div className="sidebar-header">
        <div className="sidebar-header-info d-flex justify-between">
          <div className="d-flex align-center">
            <MemoItineraryModalHeader width="30px" height="30px" />
            <div className="font-500 ml-1">Trip itinerary</div>
          </div>
          {/* <div className="destinations-count">{destinations.length ?? 0}</div> */}
        </div>
      </div>
      {!isDone && (
        <div
          className="d-flex align-center itinerary-sidebar-toggle-btn"
          onClick={onCollapseToggle}
        >
          <MemoLeftArrowIcon width="12px" />
          <div className="tooltip">
            {collapsed ? "Expand side panel" : "Click to collapse"}
          </div>
        </div>
      )}
      <div
        className="sidebar-list-container itinerary"
        style={{
          height: height - (window.isMobile ? 142 : 100),
        }}
      >
        <div className="sidebar-listitems-container">
          {destinations?.map((destination, index) => (
            <div className="sidebar-listitem d-flex" key={destination.location}>
              <div className="sidebar-lineitem-destination-count mr-3">
                {index + 1}
              </div>
              <div className="d-flex justify-between w-100">
                <div className="d-grid">
                  <Link
                    to={`/notes?tripUrl=${tripUrl}&itineraryId=${destination.id}`}
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "#2c2c2c",
                    }}
                  >
                    <h2 className="sidebar-lineitem-destination-name">
                      {destination.location}
                    </h2>
                  </Link>
                  <div className="sidebar-lineitem-destination-dates">
                    {format(new Date(destination.startDate), "dd-MMM")} ~{" "}
                    {format(new Date(destination.endDate), "dd-MMM")}
                  </div>
                </div>
                {!isDone && (
                  <>
                    <div className="sidebar-lineitem-edit">
                      {destination.notes && destination.notes.length > 0 ? (
                        <img
                          src={Notes}
                          alt="attachment"
                          className="notes-icon"
                        />
                      ) : (
                        <img
                          src={Notes}
                          alt="attachment"
                          className="notes-icon"
                          style={{ opacity: "0.4" }}
                        />
                      )}

                      {destination.notes &&
                      destination.notes.length > 0 &&
                      destination.notes[0].attachment &&
                      destination.notes[0].attachment.length > 0 ? (
                        <img
                          src={Attachment}
                          alt="attachment"
                          className="attach-icon"
                        />
                      ) : (
                        <img
                          src={Attachment}
                          alt="attachment"
                          className="attach-icon"
                          style={{ opacity: "0.4" }}
                        />
                      )}

                      {/* <Link
                        to={{
                          pathname: "/notes",
                          state: { data: destination }, // your data array of objects
                        }}
                        style={{ cursor: "pointer", textDecoration: "none" }}
                      >
                        Edit
                      </Link> */}

                      <Link
                        to={`/notes?tripUrl=${tripUrl}&itineraryId=${destination.id}`}
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "#2c2c2c",
                        }}
                      >
                        Edit
                      </Link>
                    </div>
                    {destinations && destinations.length > 1 && (
                      <div
                        className="sidebar-lineitem-clear"
                        onClick={() => {
                          setItineraryToDelete(destination);
                          deleteModalOpen();
                        }}
                      >
                        &times;
                      </div>
                    )}
                  </>
                )}
              </div>
              {index < destinations.length - 1 && (
                <span className="d-grid sidebar-items-gap">
                  <span className="dotted-line" />
                  <MemoSidebarPlane fill="#000" />
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="sidebar-btn-wrapper">
          <Button onClick={setNewDestination} className="no-collapse">
            Add another city
          </Button>
        </div>
      </div>
    </div>
  );
};
