import React, { useState } from "react";
import Div100vh from "react-div-100vh";
import useVisibleState from "hooks/useVisibleStates";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { DashboardTopbar } from "templates/Header/DashboardTopbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "atoms/Button";
import ValidationLogo from "assets/validation.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "../App.css";
import AuthService from "../services/AuthService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

const eye = <FontAwesomeIcon icon={faEye} />;
let id = "";

const SetPassword = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  //const token = parseInt(params.get("token"), 10);
  const token = params.get("token");
  const id = parseInt(params.get("id"), 10);

  const history = useHistory();
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Minimum 8 characters")
        .required("Required!"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Password's not match")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      const payload = {
        userId: id,
        token: token,
        password: values.confirm_password,
      };
      AuthService.resetPassword(payload)
        .then((response) => {
          if (response.status === 200 && response.data.status === "SUCCESS") {
            toast.success(response.data.message);
            setIsSuccess((wasOpened) => !wasOpened);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });

  const [isDashboard, setDashboard] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Div100vh className="dashboard">
      <DashboardTopbar isDashboard={isDashboard} />

      <div className="set-pwd-wrapper">
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        {!isSuccess && (
          <div className="rp-container">
            <div className="heading-type-one">Set New Password</div>
            <form onSubmit={formik.handleSubmit}>
              <div className="pass-wrapper">
                <div className="input-container-primary">
                  <div>
                    <label
                      className="modal-heading"
                      style={{
                        lineHeight: "18.75px",
                        fontWeight: 500,
                      }}
                    >
                      New Password
                    </label>
                  </div>
                  <div>
                    {formik.errors.password && formik.touched.password && (
                      <div style={{ display: "flex" }}>
                        <img
                          src={ValidationLogo}
                          alt="validation logo"
                          style={{ marginRight: "8px" }}
                        />
                        <p style={{ color: "#F15BB5", margin: 0 }}>
                          {formik.errors.password}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <input
                  className="input"
                  placeholder="Enter New Password"
                  type={newPasswordShown ? "text" : "password"}
                  maxLength={40}
                  autoFocus
                  style={{ margin: 0, width: "100%", marginBottom: "24px" }}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <i onClick={toggleNewPasswordVisiblity}>{eye}</i>
              </div>

              <div className="pass-wrapper">
                <div className="input-container-primary">
                  <div>
                    <label
                      className="modal-heading"
                      style={{
                        lineHeight: "18.75px",
                        fontWeight: 500,
                      }}
                    >
                      Confirm Password
                    </label>
                  </div>
                  <div>
                    {formik.errors.confirm_password &&
                      formik.touched.confirm_password && (
                        <div style={{ display: "flex" }}>
                          <img
                            src={ValidationLogo}
                            alt="validation logo"
                            style={{ marginRight: "8px" }}
                          />
                          <p style={{ color: "#F15BB5", margin: 0 }}>
                            {formik.errors.confirm_password}
                          </p>
                        </div>
                      )}
                  </div>
                </div>

                <input
                  className="input"
                  placeholder="Confirm Password"
                  type={confirmPasswordShown ? "text" : "password"}
                  maxLength={40}
                  autoFocus
                  style={{ margin: 0, width: "100%", marginBottom: "24px" }}
                  name="confirm_password"
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                />
                <i onClick={toggleConfirmPasswordVisiblity}>{eye}</i>
              </div>

              <div>
                <Button type="submit">Set Password</Button>
              </div>
            </form>
          </div>
        )}
        {isSuccess && (
          <div className="after-message-container">
            <div className="inside-container">
              <div className="heading-type-one">
                Password Reset Successfully
              </div>
              <div className="heading-type-two">Please Login</div>
              <div>
                <Button onClick={() => history.push("/")}>Login</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Div100vh>
  );
};

export default SetPassword;
