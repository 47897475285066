import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import LoaderSpinner from "atoms/Loader";
import Dashboard from "pages/Dashboard";
import TripNotes from "pages/TripNotes";
import TripItinerary from "pages/TripItinerary";
import SetPassword from "pages/SetPassword";
import Profile from "pages/Profile";

const Home = React.lazy(() => import(/* webpackChunkName: 'Home' */ "../home"));
// const MapView = React.lazy(() =>
//   import(/* webpackChunkName: 'MapView' */ "../pages/MapView/MapView")
// );

// function to guard the component for private access
const authGuard = (Component) => () => {
  return window.sessionStorage.getItem("authToken") ? (
    <Component />
  ) : (
    <Redirect to="/" />
  );
};

export const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<LoaderSpinner />}>
      <Switch>
        <Route path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export const AuthenticatedRoutes = () => {
  return (
    <Suspense fallback={<LoaderSpinner />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/dashboard" exact render={authGuard(Dashboard)} />
        <Route path="/notes" exact render={authGuard(TripNotes)} />
        <Route path="/itinerary" exact render={authGuard(TripItinerary)} />
        <Route path="/set-password" exact component={SetPassword} />
        <Route path="/profile" exact render={authGuard(Profile)} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};
