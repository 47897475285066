import Modal from "atoms/Modal";
import React from "react";
import { Searchbar } from "templates/Searchbar/Searchbar";

export const ChangeDestinationModal = ({
  visible,
  onClose,
  title,
  changeDestination,
}) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={title || "Change Destination"}
    >
      <div className="d-grid">
        <div
          style={{ width: "230px", margin: "auto", marginBottom: "16px" }}
          className="text-center"
        >
          Search a city to add to your trip itinerary.
        </div>
        <div className="searchbar-container">
          <Searchbar setDestination={changeDestination} autoFocus />
        </div>
      </div>
    </Modal>
  );
};
