import React, { useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { ToastContainer, toast } from "react-toastify";
import useVisibleState from "hooks/useVisibleStates";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { DashboardTopbar } from "templates/Header/DashboardTopbar";
import "../App.css";
import Filter from "assets/filter.svg";
import Destination from "assets/DefaultDestination.png";
import Itinery from "assets/itenary.png";
import Sort from "assets/sort.svg";
import Search from "assets/search.svg";
import TripShare from "assets/TripShare.svg";
import Close from "assets/close.svg";
import Button from "atoms/Button";
import { ShareModal } from "molecules/ShareModal";
import { DeleteTripModal } from "molecules/DeleteTripModal";
import { SortModal } from "molecules/SortModal";
import { FilterModal } from "molecules/FilterModal";
import Dropdown from "react-bootstrap/Dropdown";
import TripService from "../services/TripService";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import Plus from "assets/plus.svg";
import LoaderSpinner from "atoms/Loader";

let id = "";
let limit = 20;
let skip = 0;
let sortBy = "createdAt:desc";

const Dashboard = () => {
  useEffect(() => {
    if (
      window.sessionStorage.getItem("authToken") === undefined ||
      window.sessionStorage.getItem("authToken") === null
    ) {
      window.location.reload();
    } else {
      setLoading(true);
      getCategories(limit, skip, sortBy);
      getTripsOnLoad(limit, skip, sortBy);
    }
  }, []);

  //const trips = ["one", "two", "three", "four"];
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isDashboard, setDashboard] = useState(true);
  const [trips, setTrips] = useState([]);
  const [tripUrl, setTripUrl] = useState();
  const [tripId, setTripId] = useState();
  const [categories, setCategories] = useState([]);
  const [noTripFound, setNoTripFound] = useState(false);

  const {
    visible: share,
    show: shareModalOpen,
    hide: shareModalClose,
  } = useVisibleState(false);

  const {
    visible: deletemodal,
    show: deleteModalOpen,
    hide: deleteModalClose,
  } = useVisibleState(false);

  const {
    visible: sortmodal,
    show: sortModalOpen,
    hide: sortModalClose,
  } = useVisibleState(false);

  const {
    visible: filtermodal,
    show: filterModalOpen,
    hide: filterModalClose,
  } = useVisibleState(false);

  const FilterToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="filter"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img src={Filter} alt="filter" className="filterLogo" />
      <p className="action-text">{children} </p>
    </div>
  ));

  const FilterMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="filter-dropdown">{children}</ul>
        </div>
      );
    }
  );

  const SortToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="sort"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img src={Sort} alt="sort" className="sortLogo" />
      <p className="action-text">{children} </p>
    </div>
  ));

  const SortMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="sort-dropdown">{children}</ul>
        </div>
      );
    }
  );

  const getTripsOnLoad = async (limit, skip, sortBy) => {
    TripService.getTrips(limit, skip, sortBy)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setNoTripFound(false);
          setTrips(response.data.data);
        } else if (response.data.status === "EXPIRED") {
          sessionStorage.clear();
          window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTrips = async (limit, skip, sortBy) => {
    TripService.getTrips(limit, skip, sortBy)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          if (response.data.total === 0) {
            setNoTripFound(true);
          } else {
            setNoTripFound(false);
          }
          setTrips(response.data.data);
        } else if (response.data.status === "EXPIRED") {
          sessionStorage.clear();
          window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const getCategories = async (limit, skip, sortBy) => {
    TripService.getAllCategories(limit, skip, sortBy)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setCategories(response.data.data);
        } else if (response.data.status === "EXPIRED") {
          sessionStorage.clear();
          window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const getSearchedTrips = async (searchText) => {
    TripService.getSearchedTrips(limit, skip, sortBy, searchText)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          if (response.data.total === 0) {
            setNoTripFound(true);
          } else if (response.data.status === "EXPIRED") {
            sessionStorage.clear();
            window.location.reload();
          } else {
            setNoTripFound(false);
          }

          setTrips(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const createTrip = () => {
    history.push("/");
  };

  const sortTripsAZ = () => {
    sortBy = "name:asc";
    getTrips(limit, skip, sortBy);
  };

  const sortTripsZA = () => {
    sortBy = "name:desc";
    getTrips(limit, skip, sortBy);
  };

  const sortTripsDesc = () => {
    sortBy = "createdAt:desc";
    getTrips(limit, skip, sortBy);
  };

  const sortTripsAsc = () => {
    sortBy = "createdAt:asc";
    getTrips(limit, skip, sortBy);
  };

  const onDelete = (tripId) => {
    TripService.deleteTrip(tripId)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          deleteModalClose();
          getTripsOnLoad(limit, skip, sortBy);
          toast.success("Trip Successfully Deleted");
        } else if (response.data.status === "EXPIRED") {
          sessionStorage.clear();
          window.location.reload();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        toast.error("Something Went Wrong");
      });
  };

  const onSearchChange = (e) => {
    let searchText = e.target.value;
    getSearchedTrips(searchText);
  };

  const applyFilter = (category) => {
    TripService.getFilteredTrips(limit, skip, sortBy, category)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          if (response.data.total === 0) {
            setNoTripFound(true);
          } else {
            setNoTripFound(false);
          }
          setTrips(response.data.data);
        } else if (response.data.status === "EXPIRED") {
          sessionStorage.clear();
          window.location.reload();
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const getAll = () => {
    getTrips(limit, skip, sortBy);
  };

  return (
    <Div100vh className="dashboard">
      {loading && <LoaderSpinner />}
      <ToastContainer />
      <DashboardTopbar isDashboard={isDashboard} />
      <div className="page-title-strip">
        <div className="page-title">Dashboard</div>
      </div>
      <div
        className="dashboard-wrapper"
        style={{ height: "calc(100vh - 60px)" }}
      >
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        {share && (
          <ShareModal
            visible
            onClose={shareModalClose}
            generatec={shareModalClose}
            itineraries={tripUrl}
          />
        )}
        {deletemodal && (
          <DeleteTripModal
            visible
            onClose={deleteModalClose}
            tripId={tripId}
            onDelete={onDelete}
          />
        )}
        {sortmodal && (
          <SortModal
            visible
            onClose={sortModalClose}
            sortTripsAZ={sortTripsAZ}
            sortTripsZA={sortTripsZA}
            sortTripsDesc={sortTripsDesc}
            sortTripsAsc={sortTripsAsc}
          />
        )}
        {filtermodal && (
          <FilterModal
            visible
            onClose={filterModalClose}
            categories={categories}
            applyFilter={applyFilter}
            getAll={getAll}
          />
        )}

        <div className="action-bar">
          <div className="search-box">
            <img src={Search} alt="search" className="searchLogo" />
            <input
              className="share-input search-field"
              placeholder="Search Trip"
              onChange={onSearchChange}
              autoFocus
            />
          </div>

          <div className="filter-mobile" onClick={() => filterModalOpen()}>
            <img src={Filter} alt="filter" className="filterLogo" />
            <p className="action-text"> Filter </p>
          </div>

          <Dropdown className="dashboard-dd">
            <Dropdown.Toggle as={FilterToggle}>Filter</Dropdown.Toggle>
            <Dropdown.Menu as={FilterMenu}>
              <Dropdown.Item onClick={() => getTrips(limit, skip, sortBy)}>
                All Trips
              </Dropdown.Item>
              {categories?.map((category) => (
                <Dropdown.Item
                  eventKey={category.category}
                  onClick={() => applyFilter(category.id)}
                >
                  {category.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div className="sort-mobile" onClick={() => sortModalOpen()}>
            <img src={Sort} alt="sort" className="sortLogo" />
            <p className="action-text"> Sort </p>
          </div>

          <Dropdown className="dashboard-dd">
            <Dropdown.Toggle as={SortToggle}>Sort</Dropdown.Toggle>

            <Dropdown.Menu as={SortMenu}>
              <Dropdown.Item eventKey="1" onClick={() => sortTripsAZ()}>
                A-Z
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={() => sortTripsZA()}>
                Z-A
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => sortTripsDesc()}>
                Newest
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => sortTripsAsc()}>
                Oldest
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {trips && trips.length === 0 && !noTripFound && (
          <div className="noTripMessage">
            <p
              style={{
                fontSize: "24px",
                fontWeight: 500,
                lineHeight: "28px",
                color: "#2C2C2C",
                margin: 0,
              }}
            >
              No Trip saved Yet
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "18px",
                color: "#2C2C2C",
                margin: 0,
                marginTop: "15px",
              }}
            >
              No Trip saved Yet
            </p>
            <Button
              className="createTripButton"
              style={{ marginTop: "25px" }}
              onClick={() => createTrip()}
            >
              Create Your Trip
            </Button>
          </div>
        )}

        {trips && (
          <div className="tripDashboard">
            {noTripFound && (
              <div>
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "28px",
                    color: "#2C2C2C",
                    margin: 0,
                    marginTop: "25px",
                  }}
                >
                  0 Result Found
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    color: "#2C2C2C",
                    margin: 0,
                    marginTop: "8px",
                  }}
                >
                  Please Try Again
                </p>
              </div>
            )}

            {trips && trips.length > 0 && (
              <div>
                <div>
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28px",
                      color: "#2C2C2C",
                      margin: 0,
                      marginTop: "25px",
                    }}
                  >
                    {trips.length} Total Trips
                  </p>
                </div>
                <div className="tripBox">
                  <div className="add-note-card">
                    <div className="add-note" onClick={() => createTrip()}>
                      <img src={Plus} alt="back" />
                      <p>Add New Trip</p>
                    </div>
                  </div>

                  {trips?.map((trip) => (
                    <div className="tripCard" key={trip.name}>
                      <div
                        onClick={() => {
                          setTripId(trip.id);
                          deleteModalOpen();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="deleteItem"></div>
                        <img src={Close} alt="close" className="deleteIcon" />
                      </div>
                      <img
                        src={trip.itineraries[0]?.image || Itinery}
                        alt="filter"
                        className="tripCardImage"
                      />
                      <div className="tripCardBody">
                        <div>
                          <Link
                            to={`/itinerary?tripId=${trip.id}&tripUrl=${trip.tripUrl}`}
                            style={{ textDecoration: "none" }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "18px",
                                color: "#F15BB5",
                                margin: 0,
                                cursor: "pointer",
                              }}
                            >
                              {trip.name}
                            </p>
                          </Link>

                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 300,
                              lineHeight: "16px",
                              color: "#2C2C2C",
                              margin: 0,
                              marginTop: "8px",
                            }}
                          >
                            {format(
                              new Date(trip.itineraries[0].startDate),
                              "dd-MMM"
                            )}
                            ~{" "}
                            {format(
                              new Date(trip.itineraries.slice(-1)[0].endDate),
                              "dd-MMM"
                            )}
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 300,
                              lineHeight: "16px",
                              color: "#2C2C2C",
                              margin: 0,
                              marginTop: "8px",
                            }}
                          >
                            {trip.itineraries.length} Cities
                          </p>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={TripShare}
                              alt="share"
                              onClick={() => {
                                setTripUrl(trip.tripUrl);
                                shareModalOpen();
                              }}
                            />
                          </div>
                          <Link
                            to={`/itinerary?tripId=${trip.id}&tripUrl=${trip.tripUrl}`}
                            style={{ textDecoration: "none" }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "16px",
                                color: "#2C2C2C",
                                margin: 0,
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginTop: "25px",
                              }}
                            >
                              View Details
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Div100vh>
  );
};

export default Dashboard;
