import React from "react";

import "./Button.css";

export const Button = ({
  variant = "primary",
  className,
  children,
  Icon,
  onClick,
  ...props
}) => {
  return (
    <div
      onClick={onClick}
      className={`btn-container${Icon ? " btn-w-icon-container" : ""} ${
        className || ""
      }`}
    >
      {Icon && (
        <div className="btn-icon-container">
          <Icon />
        </div>
      )}
      <button
        className={`btn btn-${variant} ${!Icon ? "br-5" : ""} w-100`}
        {...props}
      >
        {children}
      </button>
    </div>
  );
};
