import React, { useCallback, useRef, useState } from "react";
import dateFnsParse from "date-fns/parse";
import dateFnsFormat from "date-fns/format";
import { addMonths, isLastDayOfMonth } from "date-fns";

import Button from "atoms/Button";
import MemoCloseIcon from "assets/icons/CloseIcon";
import MemoCalendarIcon from "assets/icons/CalendarIcon";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DefaultDestination from "assets/DefaultDestination.png";
import Itinery from "assets/itenary.png";

import "./MarkerInfoWindow.css";
import { DateUtils } from "react-day-picker";
import { Searchbar } from "templates/Searchbar/Searchbar";
import useVisibleState from "hooks/useVisibleStates";
import MemoItineraryModalHeader from "assets/icons/ItineraryModalHeader";
import { ToastContainer, toast } from "react-toastify";
import TripService from "../services/TripService";
import "react-toastify/dist/ReactToastify.css";

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = "dd-MMM, yyyy";

export const MarkerInfoWindow = ({
  destination: prevDestination,
  hideInfoWindow,
  onClear,
  markers,
  setMarkers,
  itineraryName,
}) => {
  const { visible, show, hide } = useVisibleState();
  const [destination, setDestination] = useState(prevDestination);

  const [startDate, setStartDate] = useState(destination.startDate);
  const [endDate, setEndDate] = useState(destination.endDate);

  const modifiers = { start: startDate, end: endDate };

  const lastEndDate =
    markers.findIndex((m) => m.id === destination.id) + 1
      ? markers[markers.findIndex((m) => m.id === destination.id) - 1]?.endDate
      : markers.slice(-1)?.[0]?.endDate;

  const nextStartDate =
    markers.find((m) => m.id === destination.id) &&
    markers[markers.findIndex((m) => m.id === destination.id) + 1]?.startDate;

  const ref = useRef(null);

  const onSave = useCallback(() => {
    let lat;
    let long;

    if (
      destination.latitude &&
      destination.longitude &&
      !destination.position
    ) {
      lat = destination.latitude;
      long = destination.longitude;
    } else {
      lat = destination.position.lat().toString();
      long = destination.position.lng().toString();
    }

    const payload = {
      startDate: startDate,
      endDate: endDate,
      location: destination.location,
      placeId: destination.placeId,
      latitude: lat,
      longitude: long,
      location: destination.formatted_address,
      image: destination.image,
    };

    TripService.updateItinerary(destination.id, payload)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setMarkers((ms) =>
            ms.map((m) =>
              m.id !== response.data.data.id
                ? m
                : {
                    ...response.data.data,
                    startDate: startDate,
                    endDate: endDate,
                  }
            )
          );
          hide();
          toast.success("Itinerary Updated Successfully");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.error(response.message);
      });

    // setMarkers((ms) =>
    //   ms.map((m) =>
    //     m.id !== destination.id
    //       ? m
    //       : { ...destination, startDate: startDate, endDate: endDate }
    //   )
    // );
    // hide();
  }, [startDate, endDate, setMarkers, destination, hide]);

  return (
    <div className="marker-info-window">
      <div
        className={
          itineraryName ? "marker-infowindow no-edit" : "marker-infowindow"
        }
        style={{
          height: visible ? "355px" : "inherit",
        }}
      >
        <div className="marker-infowindow-header modal-header">
          {visible ? (
            <div className="searchbar-container width-100">
              <Searchbar
                initialValue={destination.location}
                setDestination={(des) =>
                  setDestination({
                    ...des,
                    id: destination.id,
                    startDate: destination.startDate,
                    endDate: destination.endDate,
                  })
                }
              />
            </div>
          ) : (
            <>
              <div className="d-flex align-center">
                <MemoItineraryModalHeader width="24px" height="24px" />
                <div className="modal-title ml-1">{destination?.location}</div>
              </div>
              <div className="modal-close-btn" onClick={hideInfoWindow}>
                <MemoCloseIcon width="14px" height="14px" />
              </div>
            </>
          )}
        </div>

        <div
          className="datetime-modal-header"
          style={{
            border: 0,
            marginBottom: "10px",
            paddingTop: visible ? "60px" : "40px",
          }}
        >
          <img
            src={destination?.image || Itinery}
            alt="city images"
            style={{ maxHeight: "140px" }}
            className="datetime-modal-city-img"
          />
        </div>
        <div className="datetime-modal-body">
          <div className="d-flex justify-between">
            <div>
              <div className="mb-1 font-500">From</div>
              <label
                className="d-flex align-center date-wrapper"
                style={{
                  marginBottom: "24px",
                  background: visible ? "initial" : "#F6F6F6",
                }}
              >
                <div className="text-primary" style={{ height: "16px" }}>
                  <MemoCalendarIcon />
                </div>
                <DayPickerInput
                  value={dateFnsFormat(new Date(startDate), FORMAT)}
                  placeholder="Start Date"
                  format={FORMAT}
                  inputProps={{
                    disabled: !visible,
                    className: "marker-info-start-date",
                  }}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [
                      new Date(startDate),
                      { from: new Date(startDate), to: new Date(endDate) },
                    ],
                    disabledDays: {
                      before: lastEndDate ? new Date(lastEndDate) : new Date(),
                      after: new Date(endDate),
                    },
                    fromMonth: new Date(startDate),
                    modifiers,
                    month: new Date(startDate || lastEndDate),
                  }}
                  onDayPickerHide={() => ref.current?.focus()}
                  onDayChange={setStartDate}
                  // onDayChange={(day) =>
                  //   setDestination({ ...destination, startDate: day })
                  // }
                />
              </label>
            </div>
            <div className=" ml-3">
              <div className="mb-1 font-500">To</div>
              <label
                className="d-flex align-center date-wrapper"
                ref={ref}
                style={{
                  marginBottom: "24px",
                  background: visible ? "initial" : "#F6F6F6",
                }}
              >
                <div className="text-primary" style={{ height: "16px" }}>
                  <MemoCalendarIcon />
                </div>
                <DayPickerInput
                  value={dateFnsFormat(new Date(endDate), FORMAT)}
                  placeholder="End Date"
                  format={FORMAT}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  inputProps={{
                    disabled: !visible,
                    className: "marker-info-end-date",
                  }}
                  dayPickerProps={{
                    selectedDays: [
                      new Date(startDate),
                      { from: new Date(startDate), to: new Date(endDate) },
                    ],
                    disabledDays: {
                      before: startDate ? new Date(startDate) : new Date(),
                      after: new Date(nextStartDate),
                    },
                    modifiers,
                    month: isLastDayOfMonth(new Date(startDate))
                      ? addMonths(new Date(startDate), 1)
                      : new Date(startDate),
                    fromMonth: new Date(startDate),
                  }}
                  onDayChange={setEndDate}
                  // onDayChange={(day) =>
                  //   setDestination({ ...destination, endDate: day })
                  // }
                  classNames={{
                    container: "DayPickerInput",
                    overlayWrapper: "DayPickerInput-OverlayWrapper",
                    overlay: "DayPickerInput-Overlay editDatePicker",
                  }}
                />
              </label>
            </div>
          </div>
          {!itineraryName && (
            <div className="marker-infowindow-actions">
              {markers && markers.length > 1 ? (
                <Button variant="secondary" onClick={visible ? hide : onClear}>
                  {visible ? "Cancel" : "Delete"}
                </Button>
              ) : (
                <Button variant="secondary" style={{ opacity: "0.5" }}>
                  {visible ? "Cancel" : "Delete"}
                </Button>
              )}

              <Button onClick={visible ? onSave : show}>
                {visible ? "Save" : "Edit"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
