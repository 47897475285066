import React, { useState } from "react";
import Upload from "assets/upload.svg";
import S3FileUpload from "react-s3";

const UploadImage = ({ setProfilePicture }) => {
  const config = {
    bucketName: "travoyager",
    region: "ap-south-1",
    accessKeyId: "AKIA4RFJLFPD5AAXC6DD",
    secretAccessKey: "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",
  };

  const handleFileInput = (e) => {
    if (e.target.files[0] !== undefined) {
      S3FileUpload.uploadFile(e.target.files[0], config)
        .then((data) => {
          setProfilePicture(data.location);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="dp-upload">
      <input
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={handleFileInput}
      />
      <p>Upload Profile Photo</p>
      <img src={Upload} alt="upload" className="upload-logo" />
    </div>
  );
};

export default UploadImage;
