import React, { useCallback, useMemo } from "react";

import Modal from "atoms/Modal";
import Button from "atoms/Button";
import Memoemail from "assets/icons/email";
import Itinerary from "assets/ShareBg.png";
import Memotwitter from "assets/icons/twitter";
import MemoCopyIcon from "assets/icons/CopyIcon";
import Memofacebook from "assets/icons/facebook";
import MemoLinkedin from "assets/icons/Linkedin";
import Plane3 from "assets/icons/ShareBg/Vector.png";
import Plane2 from "assets/icons/ShareBg/Vector-1.png";
import Plane4 from "assets/icons/ShareBg/Vector-2.png";
import Plane1 from "assets/icons/ShareBg/Vector-3.png";
import Plane5 from "assets/icons/ShareBg/Vector-4.png";
import Plane6 from "assets/icons/ShareBg/Vector-5.png";

export const ShareModal = ({ onClose, itineraries, ...props }) => {
  const link = useMemo(
    () =>
      window.location.protocol +
      "//" +
      window.location.hostname +
      "?itinerary=" +
      itineraries,
    [itineraries]
  );

  const copyLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(link);
    } catch (err) {
      console.error(err);
    }
  }, [link]);

  return (
    <Modal title="Share Your Trip" onClose={onClose} {...props}>
      <div
        className="datetime-modal-header"
        style={{ borderBottom: 0, position: "relative", paddingBottom: "10px" }}
      >
        <img
          src={Itinerary}
          alt="city images"
          className="datetime-modal-city-img"
        />
        <img
          src={Plane1}
          style={{ position: "absolute", top: "3.5rem", left: "4rem" }}
          alt=""
        />
        <img
          src={Plane2}
          style={{ position: "absolute", top: "2rem", right: "4rem" }}
          alt=""
        />
        <img
          src={Plane3}
          style={{ position: "absolute", bottom: "1.5rem", left: "9rem" }}
          alt=""
        />
        <img
          src={Plane4}
          style={{ position: "absolute", bottom: "2rem", right: "5rem" }}
          alt=""
        />
        <img
          src={Plane5}
          style={{ position: "absolute", top: "1.5rem", left: "9.8rem" }}
          alt=""
        />
        <img
          src={Plane6}
          style={{ position: "absolute", top: "2.5rem", right: "10rem" }}
          alt=""
        />
        <div className="share-modal-heading">
          Share your trip anywhere you want
        </div>
      </div>
      <div
        className="d-grid mt-3"
        style={{ gridGap: "30px", gridAutoFlow: "column", gap: "30px" }}
      >
        <a
          className="share-modal-item w-100"
          href={`https://www.facebook.com/sharer/sharer.php?quote=Check my itinerary for my upcoming trip on Travoyager.&u=${encodeURIComponent(
            link
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Memofacebook />
        </a>
        <a
          className="share-modal-item w-100"
          href={`http://twitter.com/share?text=Check my itinerary for my upcoming trip on Travoyager.&url=${encodeURIComponent(
            link
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Memotwitter />
        </a>
        <a
          className="share-modal-item w-100"
          href={`https://www.linkedin.com/sharing/share-offsite?url=${encodeURIComponent(
            link
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MemoLinkedin />
        </a>
        <a
          className="share-modal-item w-100"
          href={`mailto:?subject=Upcoming trip&body=Hey there, Checkout my upcoming trip using this link. ${link}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Memoemail />
        </a>
      </div>
      <div className="d-flex mt-3">
        <input value={link} className="share-modal-link" />
        <div className="share-modal-item ml-3" onClick={copyLink}>
          <MemoCopyIcon />
        </div>
      </div>
      {/* <div
        className="d-grid"
        style={{
          paddingTop: "20px",
          gap: "20px",
          gridGap: "20px",
          gridAutoFlow: "column",
        }}
      >
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button onClick={copyLink}>Copy Link</Button>
      </div> */}
    </Modal>
  );
};
