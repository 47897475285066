import React, { useCallback, useEffect, useRef, useState } from "react";

import MemoSearchIcon from "assets/icons/SearchIcon";

import "./searchbar.css";
import MemoCloseIcon from "assets/icons/CloseIcon";

export const Searchbar = ({ setDestination, initialValue, autoFocus }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const ref = useRef(null);

  const google = window.google || {};

  const toggelVisible = useCallback(() => {
    ref.current.focus();
  }, []);

  const onClear = useCallback(() => {
    setValue("");
    ref.current.focus();
  }, []);

  useEffect(() => {
    if (ref.current && google.maps) {
      const autocomplete = new google.maps.places.Autocomplete(ref.current, {
        types: ["(cities)"],
      });
      autocomplete.addListener("place_changed", () => {
        const places = autocomplete.getPlace();
        if (places?.formatted_address && typeof setDestination === "function") {
          setDestination({
            id: Math.random(),
            placeId: places.place_id,

            location:
              places?.address_components?.find(
                (obj) => obj.types[0] === places?.types?.[0]
              )?.long_name +
              ", " +
              places?.address_components?.find(
                (obj) => obj.types[0] === "country"
              )?.long_name,
              
            position: places.geometry?.location,

            city: places?.address_components?.find(
              (obj) => obj.types[0] === places?.types?.[0]
            )?.long_name,
            state: places?.address_components?.find(
              (obj) => obj.types[0] === "administrative_area_level_1"
            )?.short_name,
            country: places?.address_components?.find(
              (obj) => obj.types[0] === "country"
            )?.long_name,
            image: places?.photos?.[0].getUrl(),
            formatted_address: places?.formatted_address,
          });
        }
        return false;
      });
    }
  }, [setDestination, google.maps]);

  return (
    <div className="searchbar">
      <div className="searchbar-icon" onClick={toggelVisible}>
        <MemoSearchIcon />
      </div>
      {!!value && (
        <div className="search-clear" onClick={onClear}>
          <MemoCloseIcon />
        </div>
      )}
      <input
        className="search-input"
        placeholder="Enter a city name"
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        list="destinations"
        ref={ref}
        autoFocus={autoFocus}
      />
    </div>
  );
};
