import React, { FC, useState } from "react";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "routes";

export const AuthGate = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);

  if (isLoggedIn) {
    return <AuthenticatedRoutes />;
  }

  return <UnauthenticatedRoutes />;
};
