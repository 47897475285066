import http from "../http-common";

const getUserById = (userId) => {
  return http.get(`/v1/users/${userId}`);
};

const updateUser = (userId, data) => {
  return http.patch(`/v1/users/${userId}`, data);
};

export default {
  getUserById,
  updateUser,
};
