import React, { useState } from "react";
import { Map } from "pages/Map";
import Marker from "react-google-maps/lib/components/Marker";
import { colors } from "settings/constants";
import { InfoWindow, Polyline } from "react-google-maps";
import { MarkerInfoWindow } from "organism/MarkerInfoWindow";
import { useCallback } from "react";
// import { InfoModal } from "molecules/InfoModal";
import Div100vh from "react-div-100vh";
import { MAP } from "react-google-maps/lib/constants";

const CurveLine = ({ map, start, end }) => {
  const pointC = start,
    pointD = end,
    // stroke options
    strokeColor = "#f15bb5",
    strokeSize = 2,
    strokeOpacity = 1;

  const GmapsCubicBezier = function (
    latlong1,
    latlong2,
    latlong3,
    latlong4,
    resolution,
    map
  ) {
    var lat1 = latlong1.lat();
    var long1 = latlong1.lng();
    var lat2 = latlong2.lat();
    var long2 = latlong2.lng();
    var lat3 = latlong3.lat();
    var long3 = latlong3.lng();
    var lat4 = latlong4.lat();
    var long4 = latlong4.lng();

    var points = [];

    for (var it = 0; it <= 1; it += resolution) {
      points.push(
        this.getBezier(
          {
            x: lat1,
            y: long1,
          },
          {
            x: lat2,
            y: long2,
          },
          {
            x: lat3,
            y: long3,
          },
          {
            x: lat4,
            y: long4,
          },
          it
        )
      );
    }
    var path = [];
    for (var i = 0; i < points.length - 1; i++) {
      path.push(new window.google.maps.LatLng(points[i].x, points[i].y));
      path.push(
        new window.google.maps.LatLng(points[i + 1].x, points[i + 1].y, false)
      );
    }
    // middlePoint = points[points.length - 2];

    var FlightLine = (
      <Polyline
        path={path}
        options={{
          geodesic: true,
          strokeColor: strokeColor,
          strokeOpacity: 0,
          strokeWeight: strokeSize,
          icons: [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: strokeOpacity,
                scale: strokeSize,
              },
              offset: "0",
              repeat: "16px",
            },
          ],
        }}
      />
    );

    return FlightLine;
  };

  GmapsCubicBezier.prototype = {
    B1: function (t) {
      return t * t * t;
    },
    B2: function (t) {
      return 3 * t * t * (1 - t);
    },
    B3: function (t) {
      return 3 * t * (1 - t) * (1 - t);
    },
    B4: function (t) {
      return (1 - t) * (1 - t) * (1 - t);
    },
    getBezier: function (C1, C2, C3, C4, percent) {
      var pos = {};
      pos.x =
        C1.x * this.B1(percent) +
        C2.x * this.B2(percent) +
        C3.x * this.B3(percent) +
        C4.x * this.B4(percent);
      pos.y =
        C1.y * this.B1(percent) +
        C2.y * this.B2(percent) +
        C3.y * this.B3(percent) +
        C4.y * this.B4(percent);
      return pos;
    },
  };

  // let middlePoint = { lat: 0, lng: 0 };
  // let angle = 0;
  function drawCurve(P11, P22, map) {
    const P1 =
      typeof P11.lat === "function" ? P11 : new window.google.maps.LatLng(P11);
    const P2 =
      typeof P22.lat === "function" ? P22 : new window.google.maps.LatLng(P22);
    const lineLength =
      window.google.maps.geometry.spherical.computeDistanceBetween(P1, P2);
    const lineHeading = window.google.maps.geometry.spherical.computeHeading(
      P1,
      P2
    );
    let lineHeading1 = 0;
    let lineHeading2 = 0;
    if (lineHeading < 0) {
      lineHeading1 = lineHeading + 40;
      lineHeading2 = lineHeading + 130;
    } else {
      lineHeading1 = lineHeading + -40;
      lineHeading2 = lineHeading + -130;
    }
    const pA = window.google.maps.geometry.spherical.computeOffset(
      P1,
      lineLength / 3,
      lineHeading1
    );
    const pB = window.google.maps.geometry.spherical.computeOffset(
      P2,
      lineLength / 3,
      lineHeading2
    );
    // angle = lineHeading;

    // console.log(angle);

    // const middlePoint1 = window.google.maps.geometry.spherical.computeOffset(
    //   P1,
    //   lineLength / 2,
    //   lineHeading
    // );

    // middlePoint = window.google.maps.geometry.spherical.computeOffset(
    //   middlePoint1,
    //   lineLength / 7.8,
    //   lineHeading + (lineHeading < 0 ? 90 : -90)
    // );

    const polyline = new GmapsCubicBezier(P1, pA, pB, P2, 0.01, map);

    return polyline;
  }

  if (map) {
    return (
      <>
        {drawCurve(pointC, pointD, map)}
        {/* <Marker
          position={middlePoint}
          icon={{
            url:
              "data:image/svg+xml," +
              // encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="30" height="51" viewBox="0 0 30 31" fill="none" style="transform: rotate(${
              //   angle + 100
              // }deg);">
              encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#f15bb5" version="1.1" width="1em" height="1em" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"
              style="enable-background:new 0 0 512 512; transform: rotate(${
                angle + angle > 0 ? -130 : 10
              }deg);">
              <g>
              <path d="M367.954,213.588L160.67,5.872c-7.804-7.819-20.467-7.831-28.284-0.029c-7.819,7.802-7.832,20.465-0.03,28.284    l207.299,207.731c7.798,7.798,7.798,20.486-0.015,28.299L132.356,477.873c-7.802,7.819-7.789,20.482,0.03,28.284    c3.903,3.896,9.016,5.843,14.127,5.843c5.125,0,10.25-1.958,14.157-5.873l207.269-207.701    C391.333,275.032,391.333,236.967,367.954,213.588z"/>
            </g>
            </svg>`),
            // <path d="M21.5385 26.6583L10.1348 18.1939L5.72954 18.8459C5.03411 18.9488 4.31047 18.8982 3.61173 18.6906L3.14966 18.5279C2.53474 18.3463 2.09528 17.8408 2.00156 17.2075C1.90783 16.5742 2.18204 15.9631 2.69507 15.6265L3.13494 15.3063C3.72173 14.9205 4.39865 14.6626 5.09514 14.5595L9.50035 13.9076L17.962 2.50648C18.3929 1.92586 19.0235 1.5478 19.7393 1.44186L20.9952 1.256C21.1913 1.22697 21.3872 1.30747 21.5058 1.46516C21.6255 1.62264 21.6501 1.83365 21.5674 2.01452L16.5907 13.0707C18.2267 12.9304 19.6001 12.8651 21.2716 12.7864L22.9845 12.707L24.9078 8.5886C24.9862 8.42261 25.14 8.30785 25.3201 8.28121L26.3916 8.12262C26.5534 8.09868 26.7186 8.14978 26.8392 8.26114C26.9609 8.37345 27.0241 8.5338 27.012 8.69656L26.6616 13.5578L28.4051 18.1092C28.4194 18.1465 28.4293 18.1845 28.4351 18.2231C28.453 18.3442 28.4297 18.468 28.3668 18.5759C28.2826 18.7175 28.1393 18.8143 27.9775 18.8383L26.9059 18.9969C26.7259 19.0235 26.5444 18.9584 26.4234 18.8219L23.3929 15.4366L21.725 15.8576C20.1045 16.266 18.7722 16.6012 17.1656 16.9408L25.1292 26.0819C25.261 26.2321 25.2972 26.4403 25.2292 26.6245C25.1615 26.8108 24.9974 26.9446 24.8013 26.9736L23.5454 27.1595C22.8296 27.2655 22.1167 27.0874 21.5385 26.6583Z" fill="#F15BB5"/>
            // origin: { x: 0, y: -40 },
            // anchor: {
            //   x: 0,
            //   y:
            //     angle > 0
            //       ? window.google.maps.geometry.spherical.computeDistanceBetween(
            //           new window.google.maps.LatLng(pointC),
            //           new window.google.maps.LatLng(pointD)
            //         ) *
            //           0.000012 -
            //         angle * 0.1 +
            //         12
            //       : -(angle * 0.3),
            // },
          }}
          zIndex="0"
        /> */}
      </>
    );
  }
  return null;
};

export const MapView = ({
  markers = [],
  setItineraryToDelete,
  itineraryName,
  isMobile,
  deleteModalOpen,
  ...props
}) => {
  const [infoWindow, setInfoWindow] = useState();
  const [mapRef, setMapRef] = useState(null);
  const refs = {
    map: undefined,
    mapObject: undefined,
  };

  return (
    <Div100vh>
      <Map
        onMapLoad={(map) => {
          setMapRef(map);
          refs.map = mapRef;
          refs.mapObject = mapRef?.context[MAP];
        }}
      >
        {markers.length > 1 &&
          markers?.slice(1)?.map((m, i) => (
            <CurveLine
              map={mapRef}
              start={{
                lat: () => markers[i].latitude,
                lng: () => markers[i].longitude,
              }}
              end={{
                lat: () => markers[i + 1].latitude,
                lng: () => markers[i + 1].longitude,
              }}
              key={m.id}
            />
          ))}

        {markers.map((m, index) => (
          <Marker
            position={{
              lat: parseFloat(m.latitude),
              lng: parseFloat(m.longitude),
            }}
            key={m.id}
            icon={{
              url:
                // markers.findIndex((mk) => mk.placeId === m.placeId) === index
                "https://dev-squire.s3.us-east-2.amazonaws.com/Images/Pin.svg",
              // : "https://dev-squire.s3.us-east-2.amazonaws.com/Images/Pin2.svg",
              labelOrigin: { x: 14, y: 14 },
              // origin:
              //   markers.findIndex((mk) => mk.placeId === m.placeId) === index
              //     ? undefined
              //     : { x: 0, y: -15 },
            }}
            zIndex={
              markers.findIndex((mk) => mk.placeId === m.placeId) === index
                ? 1
                : 3
            }
            label={{
              text:
                markers.findIndex((mk) => mk.placeId === m.placeId) === index
                  ? (index + 1).toString()
                  : markers.findIndex((mk) => mk.placeId === m.placeId) +
                    1 +
                    "," +
                    (index + 1),
              fontSize:
                markers.findIndex((mk) => mk.placeId === m.placeId) === index
                  ? "14px"
                  : "10px",
              color: colors.primary,
            }}
            // onMouseOver={() => {
            //   console.log(m);
            //   setInfoWindow(m.id);
            //   refs.mapObject.setZoom(8);
            //   refs.mapObject.setCenter(
            //     new window.google.maps.LatLng(m.latitude, m.longitude)
            //   );
            // }}
            onClick={() => {
              setInfoWindow(m.id);
              refs.mapObject.setZoom(4);
              refs.mapObject.setCenter(
                new window.google.maps.LatLng(m.latitude, m.longitude)
              );
            }}
          >
            {
              infoWindow === m.id && (
                // (!isMobile ? (
                <InfoWindow onCloseClick={() => setInfoWindow()}>
                  <MarkerInfoWindow
                    destination={m}
                    hideInfoWindow={() => setInfoWindow()}
                    onClear={() => {
                      setItineraryToDelete(m);
                      deleteModalOpen();
                    }}
                    itineraryName={itineraryName}
                    markers={markers}
                    {...props}
                  />
                </InfoWindow>
              )
              // ) : (
              //   <InfoModal
              //     visible
              //     onEdit={onEdit}
              //     onClose={() => setInfoWindow()}
              //     onRemove={onClearHandler(m.id)}
              //     index={index}
              //     itineraryName={itineraryName}
              //     destination={m}
              //   />
              // ))
            }
          </Marker>
        ))}
      </Map>
    </Div100vh>
  );
};
