import React, { useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import useVisibleState from "hooks/useVisibleStates";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { DashboardTopbar } from "templates/Header/DashboardTopbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "atoms/Button";
import ValidationLogo from "assets/validation.svg";
import User from "assets/avatar.svg";
import Upload from "assets/upload.svg";
import Arrow from "assets/downArrow.svg";
import "../App.css";
import UserService from "../services/UserService";
import AuthService from "../services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadImage from "../molecules/UploadImage";

const eye = <FontAwesomeIcon icon={faEye} />;

let id = "";

const Profile = () => {
  useEffect(() => {
    getUserById(window.sessionStorage.getItem("userId"));
  }, []);

  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [isDashboard, setDashboard] = useState(true);
  const [user, setUser] = useState([]);
  const [profilePicture, setProfilePicture] = useState();

  const toggleOldPasswordVisiblity = () => {
    setOldPasswordShown(oldPasswordShown ? false : true);
  };

  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };

  const formik = useFormik({
    initialValues: {
      //email: window.sessionStorage.getItem("email"),
      oldPassword: "",
      newPassword: "",
    },

    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Minimum 8 characters required")
        .required("Required!"),
      oldPassword: Yup.string()
        .min(8, "Minimum 8 characters required")
        .required("Required!"),
    }),

    onSubmit: (values) => {
      //alert(JSON.stringify(values));
      changePassword(JSON.stringify(values));
    },
  });

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const payload = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      //email: event.target.email.value,
      phoneNumber: event.target.phoneNumber.value,
      city: event.target.city.value,
      state: event.target.state.value,
      country: event.target.country.value,
      avatar: profilePicture,
    };
    const userId = window.sessionStorage.getItem("userId");
    updateUser(userId, payload);
  };

  const handleFirstNameChange = (e) => {
    setUser({ firstName: e.target.value });
  };

  const handleLastNameChange = (e) => {
    setUser({ lastName: e.target.value });
  };

  const handlePhoneNumberChange = (e) => {
    setUser({ phoneNumber: e.target.value });
  };

  const handleCityChange = (e) => {
    setUser({ city: e.target.value });
  };

  const handleStateChange = (e) => {
    setUser({ state: e.target.value });
  };

  const handleCountryChange = (e) => {
    setUser({ country: e.target.value });
  };

  const getUserById = async (userId) => {
    UserService.getUserById(userId)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setUser(response.data.data);
          if (response.data.data.avatar != null) {
            setProfilePicture(response.data.data.avatar);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const updateUser = async (userId, payload) => {
    UserService.updateUser(userId, payload)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setUser(response.data.data);
          sessionStorage.setItem("avatar", response.data.data.avatar);
          sessionStorage.setItem("name", response.data.data.firstName);
          toast.success(response.data.message);
          window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const changePassword = async (values) => {
    AuthService.changePassword(values)
      .then((response) => {
        console.log("response", response);
        if (response.status === 200 && response.data.status === "SUCCESS") {
          toast.success(response.data.message);
          window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  return (
    <Div100vh className="dashboard">
      <ToastContainer />
      <DashboardTopbar isDashboard={isDashboard} />
      <div className="page-title-strip">
        <div className="page-title">Your Profile</div>
      </div>

      <div className="profile-wrapper">
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />

        <div className="side-container">
          <div className="user-avatar">
            {profilePicture ? (
              <img
                src={profilePicture}
                alt="user avatar"
                className="avatar-image-user"
              />
            ) : (
              <img
                src={User}
                alt="user avatar"
                className="avatar-image-default"
              />
            )}
          </div>
          <UploadImage setProfilePicture={setProfilePicture} />
          {/* <div className="dp-upload">
            <input type="file" onChange={fileChangedHandler} />
            <p>Upload Profile Photo</p>
            <img src={Upload} alt="upload" className="upload-logo" />
          </div> */}
        </div>
        <div className="main-container">
          <div className="heading-type-one">Basic Information </div>
          <form onSubmit={handleSubmitForm}>
            <div className="bi-wrapper">
              <div className="field">
                <p className="input-label">First Name</p>
                <input
                  value={user.firstName}
                  className="input"
                  maxLength={40}
                  autoFocus
                  type="text"
                  name="firstName"
                  onChange={handleFirstNameChange}
                />
              </div>
              <div className="field">
                <p className="input-label">Last Name</p>
                <input
                  value={user.lastName}
                  className="input"
                  maxLength={40}
                  autoFocus
                  type="text"
                  name="lastName"
                  onChange={handleLastNameChange}
                />
              </div>
              <div className="field">
                <p className="input-label">Email Address</p>
                <input
                  value={user.email}
                  className="input"
                  type="email"
                  name="email"
                  disabled
                />
              </div>
              <div className="field">
                <p className="input-label">Phone Number</p>
                <input
                  value={user.phoneNumber}
                  className="input"
                  type="tel"
                  pattern="[0-9]{10}"
                  name="phoneNumber"
                  onChange={handlePhoneNumberChange}
                  autoFocus
                />
              </div>
              <div className="field">
                <p className="input-label">City</p>
                <input
                  value={user.city}
                  className="input"
                  maxLength={50}
                  type="text"
                  name="city"
                  onChange={handleCityChange}
                  autoFocus
                />
              </div>
              <div className="field">
                <p className="input-label">State</p>
                <input
                  value={user.state}
                  className="input"
                  maxLength={50}
                  type="text"
                  name="state"
                  onChange={handleStateChange}
                  autoFocus
                />
                {/* <div className="dd-state">
                  <img src={Arrow} alt="arrow" className="arrow-logo" />
                  <select
                    className="input select"
                    name="state"
                    onChange={handleStateChange}
                  >
                    <option value="NY">NY</option>
                    <option value="CA">CA</option>
                    <option selected value="TX">
                      TX
                    </option>
                    <option value="WC">WC</option>
                  </select>
                </div> */}
              </div>
              <div className="field">
                <p className="input-label">Country</p>
                <input
                  value={user.country}
                  className="input"
                  maxLength={50}
                  type="text"
                  name="country"
                  onChange={handleCountryChange}
                  autoFocus
                />
                {/* <div className="dd-country">
                  <img src={Arrow} alt="arrow" className="arrow-logo" />
                  <select
                    className="input select"
                    name="country"
                    onChange={handleCountryChange}
                  >
                    <option value="India">India</option>
                    <option value="USA">USA</option>
                    <option selected value="Mexico">
                      Mexico
                    </option>
                    <option value="Canada">Canada</option>
                  </select>
                </div> */}
              </div>
              <div className="empty-div"></div>
              <div className="empty-div"></div>

              <div className="action-buttons">
                <div style={{ width: "100%" }}>
                  <Button variant="secondary">Cancel</Button>
                </div>
                <div style={{ width: "100%" }}>
                  <Button type="submit">Update</Button>
                </div>
              </div>
            </div>
          </form>
          <div className="heading-type-one">Change Password </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="cp-wrapper">
              <div className="field pass-wrapper">
                <div className="input-container-primary">
                  <div>
                    <p className="input-label">Current Password</p>
                  </div>
                  <div>
                    {formik.errors.oldPassword && formik.touched.oldPassword && (
                      <div style={{ display: "flex" }}>
                        <img
                          src={ValidationLogo}
                          alt="validation logo"
                          style={{ marginRight: "8px" }}
                        />
                        <p style={{ color: "#F15BB5", margin: 0 }}>
                          {formik.errors.oldPassword}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <input
                  className="input"
                  placeholder="Enter Current Pasword"
                  type={oldPasswordShown ? "text" : "password"}
                  maxLength={40}
                  autoFocus
                  name="oldPassword"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                />
                <i onClick={toggleOldPasswordVisiblity} className="profile">
                  {eye}
                </i>
              </div>
              <div className="field pass-wrapper">
                <div className="input-container-primary">
                  <div>
                    <p className="input-label">New Password</p>
                  </div>
                  <div>
                    {formik.errors.newPassword && formik.touched.newPassword && (
                      <div style={{ display: "flex" }}>
                        <img
                          src={ValidationLogo}
                          alt="validation logo"
                          style={{ marginRight: "8px" }}
                        />
                        <p style={{ color: "#F15BB5", margin: 0 }}>
                          {formik.errors.newPassword}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <input
                  className="input"
                  placeholder="Enter New Password"
                  maxLength={40}
                  autoFocus
                  type={newPasswordShown ? "text" : "password"}
                  name="newPassword"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                />
                <i onClick={toggleNewPasswordVisiblity} className="profile">
                  {eye}
                </i>
              </div>

              <div className="empty-div"></div>
              <div className="action-buttons">
                <div style={{ width: "100%" }}>
                  <Button variant="secondary" type="reset">
                    Cancel
                  </Button>
                </div>
                <div style={{ width: "100%" }}>
                  <Button type="submit">Update</Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Div100vh>
  );
};

export default Profile;
