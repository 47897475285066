import React, { useContext, useMemo, useReducer } from "react";

const initialState = {
  isGlobe: false,
  itineraryName: "",
  collapsed: true,
  visible: false, // date time modal visibility,
  itinerary: true, // get name of the trip, itinerary modal visibility
  share: false, // share modal visibility
  destination: undefined, // I don't think it is needed anymore
  markers: [], // list of selected destinations
  destinationVisibile: false,
};

export const AppContext = React.createContext(initialState);

const actions = {
  setGlobe: "GLOBE_VISIBILITY",
  setItineraryName: "ITINERARY_NAME",
  setMarkers: "MAP_MARKERS",
  setCollapsed: "SIDEBAR_COLLAPSE",
  show: "DATE_TIME_VISIBILITY",
  hide: "hide",
  s: "s",
  h: "h",
  ss: "ss",
  hh: "hh",
};

const reducer = {
  [actions.setGlobe]: (state, value) => ({
    ...state,
    isGlobe: value,
  }),
};

const AppReducer = (state, action) => reducer[action.type](state, action.value);

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const appActions = useMemo(
    () =>
      Object.keys(actions).reduce(
        (acc, curr) => ({
          [curr]: (value) => dispatch({ type: actions[curr], value }),
        }),
        {}
      ),
    []
  );

  return (
    <AppContext.Provider value={{ state, actions: appActions }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
