import React from "react";

import Modal from "atoms/Modal";
import Button from "atoms/Button";
import Attachment from "assets/attachment.svg";
import "./NoteModal.css";

export const ViewNoteModal = ({
  onClose,
  urlList,
  showLoginModal,
  documents,
  noteToEdit,
  ...props
}) => {
  return (
    <Modal title="View Note" onClose={onClose} {...props}>
      {noteToEdit && (
        <div
          className="view-note-modal-header"
          style={{
            position: "relative",
          }}
        >
          <div className="input-label">Title</div>
          <input
            value={noteToEdit.title}
            className="input"
            placeholder="Enter title of the notes"
            maxLength={40}
            autoFocus
            style={{ marginBottom: "16px" }}
          />
          <div className="input-label">Description</div>
          <textarea
            value={noteToEdit.description}
            className="input"
            placeholder="Enter description"
            maxLength={400}
            autoFocus
            style={{ marginBottom: "16px" }}
          />
          {urlList.length > 0 && <div className="input-label">URLs</div>}
          {urlList.map((x, i) => {
            return (
              <div className="links">
                <a
                  href={x}
                  placeholder="Enter URLs"
                  type="url"
                  name="url"
                  target="_blank"
                >
                  {x}
                </a>
              </div>
            );
          })}
          {documents.length > 0 && (
            <div className="input-label" style={{ margin: "16px 0 8px 0" }}>
              Attachments
            </div>
          )}
          {documents.map((a) => {
            return (
              <div className="attachments" style={{ marginTop: "8px" }}>
                <img
                  src={Attachment}
                  alt="attachment"
                  className="attachment-logo"
                />
                <a href={a.url} target="_blank">
                  {a.name}
                </a>
              </div>
            );
          })}

          <div style={{ width: "100%", marginTop: "48px" }}>
            <Button onClick={onClose}>Ok</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
