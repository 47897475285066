import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useParams,
} from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Div100vh from "react-div-100vh";
import { use100vh } from "react-div-100vh";
import useVisibleState from "hooks/useVisibleStates";
import { DashboardTopbar } from "templates/Header/DashboardTopbar";
import MemoCalendarIcon from "assets/icons/CalendarIcon";
import Button from "atoms/Button";
import "../App.css";
import "../templates/Sidebar/Sidebar.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "../molecules/DateTimeModal.css";
import "react-day-picker/lib/style.css";
import Destination from "assets/DefaultDestination.png";
import Itinery from "assets/itenary.png";
import Back from "assets/back.svg";
import Edit from "assets/edit.svg";
import Plus from "assets/plus.svg";
import Close from "assets/close.svg";
import Attachment from "assets/attachment.svg";
import { addMonths, isLastDayOfMonth } from "date-fns";
import dateFnsParse from "date-fns/parse";
import dateFnsFormat from "date-fns/format";
import { DateUtils } from "react-day-picker";
import { Searchbar } from "templates/Searchbar/Searchbar";
import { AddNoteModal } from "molecules/AddNoteModal";
import { ViewNoteModal } from "molecules/ViewNoteModal";
import { EditNoteModal } from "molecules/EditNoteModal";
import { DeleteNoteModal } from "molecules/DeleteNoteModal";
import MemoLeftArrowIcon from "assets/icons/LeftArrowIcon";
import NoteService from "../services/NoteService";
import TripService from "../services/TripService";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = "dd-MMM, yyyy";

const DateTimeHeader = ({ destination, setDestination }) => (
  <div className="searchbar-container width-100 px-4">
    <Searchbar
      initialValue={destination.location}
      setDestination={setDestination}
    />
  </div>
);

let id = "";

const TripNotes = () => {
  const history = useHistory();
  //console.log("Data passed from Itinerary page",match.params.itineraryId);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const itineraryId = params.get("itineraryId");
  const tripUrl = params.get("tripUrl");

  useEffect(() => {
    getTrips(tripUrl);
    getItineraryDeatils(itineraryId);
    getNotes(itineraryId);
  }, [itineraryId]);

  const [itineraryName, setItinerayName] = useState("");
  const [notes, setNotes] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [noteToEdit, setNoteToEdit] = useState();
  const [itinerary, setItinerary] = useState();
  const [noteId, setNoteId] = useState();
  const [isDashboard, setDashboard] = useState(true);
  const [isEditShown, setIsEditShown] = useState(false);
  const [urlList, setUrlList] = useState([{}]);
  const [documents, setDocuments] = useState([]);

  const [collapsed, setCollapsed] = useState(true);

  const onCollapseToggle = useCallback(
    () => setCollapsed((prev) => !prev),
    [setCollapsed]
  );

  const {
    visible: addNoteModal,
    show: addNoteModalOpen,
    hide: addNoteModalClose,
  } = useVisibleState(false);

  const {
    visible: viewNoteModal,
    show: viewNoteModalOpen,
    hide: viewNoteModalClose,
  } = useVisibleState(false);

  const {
    visible: editNoteModal,
    show: editNoteModalOpen,
    hide: editNoteModalClose,
  } = useVisibleState(false);

  const {
    visible: deleteNoteModal,
    show: deleteNoteModalOpen,
    hide: deleteNoteModalClose,
  } = useVisibleState(false);

  const height = use100vh();

  const onDone = () => {
    getNotes(itineraryId);
  };

  const onDelete = (noteId) => {
    NoteService.deleteNote(noteId)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          toast.success("Note Deleted Successfully");
          deleteNoteModalClose();
          getNotes(itineraryId);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getTrips = (tripUrl) => {
    TripService.getTripDetailsByUrl(tripUrl)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setItinerayName(response.data?.data.trip.name);
          setMarkers(response.data?.data.itineraries);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        toast.error(response.data.message);
      });
  };

  const getNotes = async (itineraryId) => {
    NoteService.getNotes(itineraryId)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setNotes(response.data.data);
        } else if (response.data.status === "EXPIRED") {
          toast.error("Token Expired, Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const getItineraryDeatils = async (itineraryId) => {
    TripService.getItineraryById(itineraryId)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setItinerary(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        toast.error(response.data.message);
      });
  };

  const updateItinerary = (itineraryId, tripData) => {
    let lat;
    let long;

    if (tripData.latitude && tripData.longitude && !tripData.position) {
      lat = tripData.latitude;
      long = tripData.longitude;
    } else {
      lat = tripData.position.lat().toString();
      long = tripData.position.lng().toString();
    }

    const payload = {
      startDate: tripData.startDate,
      endDate: tripData.endDate,
      location: tripData.location,
      placeId: tripData.placeId,
      latitude: lat,
      longitude: long,
      location: tripData.formatted_address,
      image: tripData.image,
    };

    TripService.updateItinerary(itineraryId, payload)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setItinerary(response.data.data);
          toast.success("Itinerary Updated Successfully");
          setIsEditShown(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        console.log(response.message);
      });
  };

  const from = useMemo(
    () => (itinerary?.startDate ? new Date(itinerary?.startDate) : null),
    [itinerary]
  );

  const to = useMemo(
    () => (itinerary?.endDate ? new Date(itinerary?.endDate) : null),
    [itinerary]
  );
  const modifiers = { start: from, end: to };

  const lastEndDate = useMemo(
    () =>
      markers.findIndex((m) => m.id === itinerary?.id) + 1
        ? markers[markers.findIndex((m) => m.id === itinerary?.id) - 1]?.endDate
        : markers.slice(-1)?.[0]?.endDate,
    [markers, itinerary]
  );

  const nextStartDate = useMemo(
    () =>
      markers.find((m) => m.id === itinerary?.id) &&
      markers[markers.findIndex((m) => m.id === itinerary?.id) + 1]?.startDate,
    [markers, itinerary]
  );

  const ref = useRef(null);
  return (
    <Div100vh className="dashboard">
      <ToastContainer />
      <DashboardTopbar isDashboard={isDashboard} />
      <div className="page-title-strip">
        <div className="page-title">{itineraryName}</div>
      </div>
      <div
        className="trip-dashboard-wrapper d-flex"
        style={{ height: "calc(100vh - 60px)" }}
      >
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        {addNoteModal && (
          <AddNoteModal
            visible
            onClose={addNoteModalClose}
            onDone={onDone}
            itineraryId={itineraryId}
            documents={documents}
            setDocuments={setDocuments}
          />
        )}
        {viewNoteModal && (
          <ViewNoteModal
            visible
            urlList={urlList}
            documents={documents}
            noteToEdit={noteToEdit}
            onClose={viewNoteModalClose}
          />
        )}
        {editNoteModal && (
          <EditNoteModal
            visible
            onClose={editNoteModalClose}
            onDone={onDone}
            noteToEdit={noteToEdit}
            urlList={urlList}
            setUrlList={setUrlList}
            documents={documents}
            setDocuments={setDocuments}
          />
        )}
        {deleteNoteModal && (
          <DeleteNoteModal
            visible
            onDelete={onDelete}
            onClose={deleteNoteModalClose}
            noteId={noteId}
          />
        )}
        {itinerary && (
          <div className="trip-sidebar-mobile">
            <div
              className={`notes sidebar-container${
                collapsed ? " sidebar-collapsed" : ""
              }`}
            >
              <div className="sidebar-header">
                <div style={{ cursor: "pointer", textDecoration: "none" }}>
                  <div className="action-box" onClick={() => history.goBack()}>
                    <img src={Back} alt="back" className="action-logos" />
                    <p>Return to Itinerary</p>
                  </div>
                </div>
                {!isEditShown && (
                  <div
                    className="action-box"
                    onClick={() => setIsEditShown(true)}
                  >
                    <img src={Edit} alt="edit" className="action-logos" />
                    <p>Edit</p>
                  </div>
                )}
              </div>

              {isEditShown && (
                <DateTimeHeader
                  destination={itinerary}
                  setDestination={(des) =>
                    setItinerary({
                      ...des,
                      id: itinerary.id,
                      startDate: itinerary.startDate,
                      endDate: itinerary.endDate,
                    })
                  }
                />
              )}

              <div
                className="d-flex align-center sidebar-toggle-btn notes"
                onClick={onCollapseToggle}
              >
                <MemoLeftArrowIcon width="12px" />
                <div className="tooltip">
                  {collapsed ? "Expand side panel" : "Click to collapse"}
                </div>
              </div>

              <div
                className="trip-detail-wrapper"
                style={{
                  height: height - (window.isMobile ? 142 : 100),
                }}
              >
                <div className="datetime-modal-header">
                  <img
                    src={itinerary.image || Itinery}
                    alt="city images"
                    className="datetime-modal-city-img"
                  />
                  <div className="d-flex align-center">
                    <h2 className="datetime-modal-city-name">
                      {itinerary.location}
                    </h2>
                  </div>
                </div>
                <div className="datetime-modal-body">
                  <div className="d-flex justify-between">
                    <div>
                      <p className="text-md font-500 mt-4 mb-1">From</p>
                      <label className="d-flex align-center date-wrapper">
                        <div
                          className="text-primary"
                          style={{ height: "16px" }}
                        >
                          <MemoCalendarIcon />
                        </div>
                        {from && from != null && (
                          <DayPickerInput
                            value={from}
                            placeholder="Start Date"
                            format={FORMAT}
                            inputProps={{
                              disabled: !isEditShown,
                            }}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              selectedDays: [from, { from, to }],
                              disabledDays: {
                                before: new Date(lastEndDate),
                                after: to,
                              },
                              fromMonth: from,
                              modifiers,
                              month: from || new Date(lastEndDate),
                            }}
                            onDayPickerHide={() => ref.current?.focus()}
                            onDayChange={(day) =>
                              setItinerary({ ...itinerary, startDate: day })
                            }
                          />
                        )}
                      </label>
                    </div>
                    <div>
                      <p className="text-md font-500 mt-4 mb-1 ml-3">To</p>
                      <label
                        className="d-flex align-center date-wrapper ml-3"
                        ref={ref}
                      >
                        <div
                          className="text-primary"
                          style={{ height: "16px" }}
                        >
                          <MemoCalendarIcon />
                        </div>

                        {to && to != null && (
                          <DayPickerInput
                            value={to}
                            placeholder="End Date"
                            format={FORMAT}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            inputProps={{
                              disabled: !isEditShown,
                            }}
                            dayPickerProps={{
                              selectedDays: [from, { from, to }],
                              disabledDays: {
                                before: from || new Date(),
                                after: nextStartDate,
                              },
                              modifiers,
                              month: isLastDayOfMonth(from)
                                ? addMonths(from, 1)
                                : from,
                              fromMonth: from,
                            }}
                            onDayChange={(day) =>
                              setItinerary({ ...itinerary, endDate: day })
                            }
                            classNames={{
                              container: "DayPickerInput",
                              overlayWrapper: "DayPickerInput-OverlayWrapper",
                              overlay: "DayPickerInput-Overlay editDatePicker",
                            }}
                          />
                        )}
                      </label>
                    </div>
                  </div>
                  {isEditShown && (
                    <Button
                      onClick={() => updateItinerary(itinerary.id, itinerary)}
                    >
                      Update City
                    </Button>
                  )}
                  {/* <Button
              onClick={setMarker}
              disabled={!destination.start_date || !destination.end_date}
              variant={
                !destination.start_date || !destination.end_date
                  ? "disabled"
                  : undefined
              }
            >
              {isEdit ? "Update" : "Add"} City
            </Button> */}
                </div>
              </div>
            </div>
          </div>
        )}

        {itinerary && (
          <div className="trip-sidebar">
            <div className="action-return">
              <div className="action-box" onClick={() => history.goBack()}>
                <img src={Back} alt="back" className="action-logos" />
                <p>Return to Itinerary</p>
              </div>

              {!isEditShown && (
                <div
                  className="action-box"
                  onClick={() => setIsEditShown(true)}
                >
                  <img src={Edit} alt="edit" className="action-logos" />
                  <p>Edit</p>
                </div>
              )}
            </div>

            {isEditShown && (
              <DateTimeHeader
                destination={itinerary}
                setDestination={(des) =>
                  setItinerary({
                    ...des,
                    id: itinerary.id,
                    startDate: itinerary.startDate,
                    endDate: itinerary.endDate,
                  })
                }
              />
            )}

            <div className="trip-detail-wrapper">
              <div className="datetime-modal-header">
                <img
                  src={itinerary.image || Itinery}
                  alt="city images"
                  className="datetime-modal-city-img"
                />
                <div className="d-flex align-center">
                  <h2 className="datetime-modal-city-name">
                    {itinerary.location}
                  </h2>
                </div>
              </div>
              <div className="datetime-modal-body">
                <div className="d-flex justify-between">
                  <div>
                    <p className="text-md font-500 mt-4 mb-1">From</p>
                    <label className="d-flex align-center date-wrapper">
                      <div className="text-primary" style={{ height: "16px" }}>
                        <MemoCalendarIcon />
                      </div>
                      {from && from != null && (
                        <DayPickerInput
                          value={from}
                          placeholder="Start Date"
                          format={FORMAT}
                          formatDate={formatDate}
                          inputProps={{
                            disabled: !isEditShown,
                          }}
                          parseDate={parseDate}
                          dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: {
                              before: new Date(lastEndDate),
                              after: to,
                            },
                            fromMonth: from,
                            modifiers,
                            month: from || new Date(lastEndDate),
                          }}
                          onDayPickerHide={() => ref.current?.focus()}
                          onDayChange={(day) =>
                            setItinerary({ ...itinerary, startDate: day })
                          }
                        />
                      )}
                    </label>
                  </div>
                  <div>
                    <p className="text-md font-500 mt-4 mb-1 ml-3">To</p>
                    <label
                      className="d-flex align-center date-wrapper ml-3"
                      ref={ref}
                    >
                      <div className="text-primary" style={{ height: "16px" }}>
                        <MemoCalendarIcon />
                      </div>
                      {to && to != null && (
                        <DayPickerInput
                          value={to}
                          placeholder="End Date"
                          format={FORMAT}
                          formatDate={formatDate}
                          parseDate={parseDate}
                          inputProps={{
                            disabled: !isEditShown,
                          }}
                          dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: {
                              before: from || new Date(),
                              after: nextStartDate,
                            },
                            modifiers,
                            month: isLastDayOfMonth(from)
                              ? addMonths(from, 1)
                              : from,
                            fromMonth: from,
                          }}
                          onDayChange={(day) =>
                            setItinerary({ ...itinerary, endDate: day })
                          }
                          classNames={{
                            container: "DayPickerInput",
                            overlayWrapper: "DayPickerInput-OverlayWrapper",
                            overlay: "DayPickerInput-Overlay editDatePicker",
                          }}
                        />
                      )}
                    </label>
                  </div>
                </div>
                {/* <Button
                onClick={setMarker}
                disabled={!destination.start_date || !destination.end_date}
                variant={
                  !destination.start_date || !destination.end_date
                    ? "disabled"
                    : undefined
                }
              >
                {isEdit ? "Update" : "Add"} City
              </Button> */}
              </div>
              {isEditShown && (
                <Button
                  onClick={() => updateItinerary(itinerary.id, itinerary)}
                >
                  Update City
                </Button>
              )}
            </div>
          </div>
        )}

        <div className="trip-main">
          {notes ? (
            <div>
              <p className="box-heading">{notes.length} Notes Found</p>
            </div>
          ) : (
            <div>
              <p className="box-heading">No Note Added Yet</p>
              <p className="box-sub-heading">Please add a note</p>
            </div>
          )}

          <div className="notes-box">
            <div className="add-note-card">
              <div
                className="add-note"
                onClick={() => {
                  setDocuments([]);
                  addNoteModalOpen();
                }}
              >
                <img src={Plus} alt="back" />
                <p>Add New Notes</p>
              </div>
            </div>
            {notes?.map((note) => (
              <div className="note-card">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setNoteId(note.id);
                    deleteNoteModalOpen();
                  }}
                >
                  <div className="deleteItem"></div>
                  <img src={Close} alt="close" className="deleteIcon" />
                </div>
                <div
                  className="upperpart"
                  onClick={() => {
                    setUrlList(note.urls);
                    setDocuments(note.attachment);
                    setNoteToEdit(note);
                    viewNoteModalOpen();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <p className="note-heading">{note.title}</p>
                  {note.attachment && note.attachment.length > 0 && (
                    <div className="d-flex">
                      <img
                        src={Attachment}
                        alt="attachment"
                        style={{ marginTop: "15px" }}
                      />
                      <p className="attach-name">{note.attachment[0].name}</p>
                    </div>
                  )}

                  <p className="note-content">{note.description}</p>
                </div>
                <div className="lowerpart">
                  <p className="lower-date">
                    {format(new Date(note.createdAt), "dd-MMM-yyyy")}
                  </p>
                  <p
                    className="lower-edit"
                    onClick={() => {
                      setUrlList(note.urls);
                      setDocuments(note.attachment);
                      setNoteToEdit(note);
                      editNoteModalOpen();
                    }}
                  >
                    Edit
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Div100vh>
  );
};

export default TripNotes;
