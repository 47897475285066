import http from "../http-common";

const getNotes = (itineraryId) => {
  return http.get(`/v1/notes?itineraryId=${itineraryId}`);
};

const addNotes = (data) => {
  return http.post("/v1/notes", data);
};

const deleteNote = (noteId) => {
  return http.delete(`/v1/notes/${noteId}`);
};

const updateNotes = (noteId, data) => {
  return http.patch(`/v1/notes/${noteId}`, data);
};

export default {
  getNotes,
  addNotes,
  deleteNote,
  updateNotes,
};
