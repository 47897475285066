import React from "react";
import Modal from "atoms/Modal";
import Button from "atoms/Button";
import NoteService from "../services/NoteService";

export const DeleteNoteModal = ({ onClose, noteId, onDelete, ...props }) => {
  return (
    <Modal title="Delete Note" onClose={onClose} {...props}>
      <div
        className="delete-note-modal-header"
        style={{
          position: "relative",
        }}
      >
        <div
          className="modal-heading"
          style={{
            lineHeight: "18.13px",
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center",
            marginTop: "25px",
          }}
        >
          Are you sure you want to delete this note?
        </div>

        <div className="deleteButtons">
          <div style={{ width: "100%" }}>
            <Button
              style={{ marginTop: "25px" }}
              variant="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              style={{ marginTop: "25px" }}
              onClick={() => onDelete(noteId)}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
