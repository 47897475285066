import React from "react";
import Modal from "atoms/Modal";
import Button from "atoms/Button";

export const FilterModal = ({
  onClose,
  applyFilter,
  categories,
  getAll,
  ...props
}) => {
  return (
    <Modal title="Filter" onClose={onClose} {...props}>
      <div
        className="sort-modal-header"
        style={{
          position: "relative",
        }}
      >
        <div className="modal-heading">
          <ul className="dd-list">
            <li onClick={() => getAll()}>All Trips</li>
            {categories?.map((category) => (
              <li
                eventKey={category.category}
                onClick={() => applyFilter(category.id)}
              >
                {category.name}
              </li>
            ))}

            {/* <li>In-Progress</li>
            <li>Future</li>
            <li>Completed Trips</li> */}
          </ul>
        </div>
        <div style={{ width: "100%" }}>
          <Button style={{ marginTop: "120px" }} onClick={() => onClose()}>
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};
