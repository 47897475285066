import * as React from "react";

function ItineraryModalHeader(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#F15BB5" />
      <path
        d="M7.403 8.26l6.331 1.697 1.78-1.027c.281-.162.6-.264.936-.293l.226-.009a.743.743 0 01.717.372.738.738 0 01-.03.796l-.134.213c-.186.265-.433.49-.715.653l-1.78 1.027-1.695 6.33a1.24 1.24 0 01-.582.76l-.508.292a.249.249 0 01-.374-.228l.259-5.59c-.679.337-1.257.598-1.961.916l-.722.325-.126 2.094a.25.25 0 01-.125.202l-.433.25a.251.251 0 01-.364-.141l-.675-2.146-1.52-1.658a.25.25 0 01.06-.386l.432-.25a.248.248 0 01.237-.006l1.875.939.645-.464c.626-.45 1.14-.82 1.773-1.24l-4.97-2.57a.249.249 0 01-.135-.217.249.249 0 01.124-.222l.508-.293a1.24 1.24 0 01.946-.126z"
        fill="#AA2675"
      />
    </svg>
  );
}

const MemoItineraryModalHeader = React.memo(ItineraryModalHeader);
export default MemoItineraryModalHeader;
