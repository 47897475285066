import React, { useState } from "react";
import Modal from "atoms/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "atoms/Button";
import Upload from "assets/upload.svg";
import Attachment from "assets/attachment.svg";
import Close from "assets/close-black.svg";
import NoteService from "../services/NoteService";
import { ToastContainer, toast } from "react-toastify";
import UploadDocument from "./UploadDocument";
import ValidationLogo from "assets/validation.svg";
import "react-toastify/dist/ReactToastify.css";
import "./NoteModal.css";

export const EditNoteModal = ({
  onClose,
  showLoginModal,
  noteToEdit,
  onDone,
  urlList,
  setUrlList,
  documents,
  setDocuments,
  ...props
}) => {
  // handle input change
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const list = [...urlList];
    list[index] = value;
    setUrlList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...urlList];
    list.splice(index, 1);
    setUrlList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setUrlList([...urlList, ""]);
  };

  const formik = useFormik({
    initialValues: {
      title: noteToEdit.title,
      description: noteToEdit.description,
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Title required"),
    }),

    onSubmit: (values) => {
      values.urls = urlList;
      values.attachment = documents;
      NoteService.updateNotes(noteToEdit.id, values)
        .then((response) => {
          if (response.status === 200 && response.data.status === "SUCCESS") {
            toast.success("Note Updated Successfully");
            onDone();
            onClose();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((response) => {
          console.log(response.message);
        });
    },
  });

  return (
    <Modal title="Edit Note" onClose={onClose} {...props}>
      {noteToEdit && (
        <div
          className="edit-note-modal-header"
          style={{
            position: "relative",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <div>
                <label
                  className="modal-heading"
                  style={{
                    lineHeight: "18.75px",
                    fontWeight: 500,
                    marginBottom: "8px",
                  }}
                >
                  Title
                </label>
              </div>
              <div>
                {formik.errors.title && formik.touched.title && (
                  <div style={{ display: "flex" }}>
                    <img
                      src={ValidationLogo}
                      alt="validation logo"
                      style={{ marginRight: "8px" }}
                    />
                    <p style={{ color: "#F15BB5", margin: 0 }}>
                      {formik.errors.title}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <input
              className="input"
              placeholder="Enter title of the notes"
              maxLength={40}
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              autoFocus
              style={{ marginBottom: "16px" }}
            />
            <div className="input-label">Description</div>
            <textarea
              className="input"
              placeholder="Enter description"
              maxLength={400}
              type="text"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              autoFocus
              style={{ marginBottom: "16px" }}
            />
            {/* <div className="input-label">URLs</div>
            <input
              className="input"
              placeholder="Enter URLs"
              maxLength={40}
              autoFocus
              type="url"
              name="url"
              value={formik.values.url}
              onChange={formik.handleChange}
              style={{ marginBottom: "8px" }}
            />
            <div className="action-label edit">Add More</div> */}

            {urlList.map((x, i) => {
              return (
                <div className="box">
                  <input
                    value={x}
                    className="input"
                    placeholder="Enter URLs"
                    style={{ marginBottom: "8px" }}
                    type="url"
                    name="url"
                    onChange={(e) => handleInputChange(e, i)}
                  />

                  <div className="btn-box">
                    {urlList.length - 1 === i && (
                      <div
                        className="action-label add"
                        onClick={() => handleAddClick()}
                      >
                        Add More
                      </div>
                    )}
                    {urlList.length !== 1 && (
                      <div
                        className="action-label remove"
                        onClick={() => handleRemoveClick(i)}
                      >
                        Remove
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            {documents &&
              documents?.map((document) => (
                <div className="attachments edit">
                  <img
                    src={Attachment}
                    alt="attachment"
                    className="attachment-logo"
                  />
                  <p>{document.name}</p>
                  <img
                    src={Close}
                    alt="close"
                    className="close-logo-black"
                    onClick={() =>
                      setDocuments((prev) =>
                        prev.filter((p) => p.name !== document.name)
                      )
                    }
                  />
                </div>
              ))}

            {/* <div className="attachments edit">
              <img
                src={Attachment}
                alt="attachment"
                className="attachment-logo"
              />
              <p>Burj Al Arab Reservation.PDF</p>
              <img src={Close} alt="close" className="close-logo-black" />
            </div> */}

            <UploadDocument setDocuments={setDocuments} />

            <div className="deleteButtons">
              <div style={{ width: "100%" }}>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </div>
              <div style={{ width: "100%" }}>
                <Button type="submit">Save</Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};
