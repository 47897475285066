import React, { useState } from "react";
import Modal from "atoms/Modal";
import Button from "atoms/Button";

export const SortModal = ({
  sortTripsAZ,
  sortTripsZA,
  sortTripsDesc,
  sortTripsAsc,
  onClose,
  ...props
}) => {
  const [sortBy, setSortBy] = useState();

  return (
    <Modal title="Sort" onClose={onClose} {...props}>
      <div
        className="sort-modal-header"
        style={{
          position: "relative",
        }}
      >
        <div className="modal-heading">
          <ul className="dd-list">
            <li onClick={sortTripsAZ}>A-Z</li>
            <li onClick={sortTripsZA}>Z-A</li>
            <li onClick={sortTripsDesc}>Newest</li>
            <li onClick={sortTripsAsc}>Oldest</li>
          </ul>
        </div>
        <div style={{ width: "100%" }}>
          <Button style={{ marginTop: "120px" }} onClick={() => onClose()}>
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};
