import axios from "axios";

let authToken = window.sessionStorage.getItem("authToken");

export const assignAuthToken = (token) => {
  authToken = token;
};

const http = axios.create({
  baseURL: "https://travoyager.jamsfyapi.com",
});

http.interceptors.request.use(function (config) {
  return {
    ...config,
    headers: {
      ...config.headers,
      "Content-Type": "application/json",
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : undefined),
    },
  };
});

export default http;
