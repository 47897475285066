import React, { useState } from "react";
import { compose, withProps } from "recompose";
import { withGoogleMap, GoogleMap } from "react-google-maps";

// import { GOOGLE_MAP_KEY } from "settings/constants";

export const Map = React.memo(
  compose(
    withProps({
      // googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      // loadingElement: <div style={{ height: `100%` }} />,
      containerElement: (
        <div
          style={{
            height: window.isMobile
              ? `calc(100vh - 101px)`
              : "calc(100vh - 53px)",
          }}
        />
      ),
      mapElement: <div style={{ height: `100%` }} />,
    }),
    // withScriptjs,
    withGoogleMap
  )(
    ({
      children,
      defaultCenter = { lat: 100, lng: 0 },
      onMapLoad,
      ...rest
    }) => {
      const [zoom, setZoom] = useState(1.8);
      return (
        <div>
          <GoogleMap
            defaultZoom={1.8}
            defaultCenter={defaultCenter}
            zoom={zoom}
            ref={onMapLoad}
            defaultOptions={{
              streetViewControl: false,
              mapTypeControl: false,
              rotateControl: false,
              zoomControl: false,
              fullscreenControl: false,
              minZoom: 1.85,
              gestureHandling: "greedy",
              restriction: {
                latLngBounds: {
                  north: 85,
                  south: -85,
                  west: -180,
                  east: 180,
                },
              },
              styles: [
                { elementType: "geometry", stylers: [{ color: "#36C0AE" }] },
                {
                  elementType: "labels.text.stroke",
                  stylers: [{ color: "#fff" }],
                },
                {
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#fff" }],
                },
                {
                  featureType: "administrative.locality",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#fff" }],
                },
                {
                  featureType: "road",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "administrative.country",
                  elementType: "geometry",
                  stylers: [{ color: "#ffffff" }, { weight: 0.4 }],
                },
                {
                  featureType: "administrative.province",
                  elementType: "geometry",
                  stylers: [{ color: "#ffffff" }, { weight: 0.4 }],
                },
                {
                  featureType: "administrative.locality",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#ffffff" }, { weight: 0.2 }],
                },
                {
                  featureType: "administrative.country",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#ffffff" }, { weight: 0.3 }],
                },
                {
                  featureType: "administrative.province",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#ffffff" }, { weight: 0.1 }],
                },
                {
                  featureType: "water",
                  elementType: "geometry",
                  stylers: [{ color: "#0362BA" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#fff" }],
                },
              ],
            }}
            {...rest}
          >
            {children}
          </GoogleMap>
          <div className="map-zoom-btn-container">
            <div onClick={() => setZoom((z) => (z > 21 ? z : z + 1))}>+</div>
            <div onClick={() => setZoom((z) => (z > 1.8 ? z - 1 : z))}>
              &ndash;
            </div>
          </div>
        </div>
      );
    }
  )
);
