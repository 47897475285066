import http from "../http-common";

const login = (data) => {
  return http.post("/v1/auth/login", data);
};

const register = (data) => {
  return http.post("/v1/auth/signup", data);
};

const forgotPassword = (data) => {
  return http.post("/v1/auth/forgot", data);
};

const changePassword = (data) => {
  return http.post("/v1/auth/change", data);
};

const googleLogin = (data) => {
  return http.post("/v1/auth/google", data);
};

const refreshToken = () => {
  return http.post("/v1/auth/refresh");
};

const resetPassword = (data) => {
  return http.post("/v1/auth/reset", data);
};

export default {
  login,
  register,
  forgotPassword,
  googleLogin,
  changePassword,
  refreshToken,
  resetPassword,
};
