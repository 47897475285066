import * as React from "react";

function twitter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M15 2.85a6.411 6.411 0 01-1.772.485 3.058 3.058 0 001.353-1.7 6.145 6.145 0 01-1.95.744 3.074 3.074 0 00-5.32 2.103c0 .244.021.478.072.701a8.704 8.704 0 01-6.339-3.216 3.08 3.08 0 00.945 4.11A3.037 3.037 0 01.6 5.698v.034a3.09 3.09 0 002.464 3.021 3.07 3.07 0 01-.807.102c-.196 0-.395-.012-.582-.053a3.104 3.104 0 002.874 2.142 6.18 6.18 0 01-3.813 1.312c-.252 0-.494-.011-.736-.042a8.657 8.657 0 004.718 1.38c5.658 0 8.752-4.688 8.752-8.75 0-.137-.005-.268-.011-.398A6.133 6.133 0 0015 2.849z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const Memotwitter = React.memo(twitter);
export default Memotwitter;
