import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import Firebase from "firebase";
import querystring from "querystring";
import Div100vh from "react-div-100vh";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MapView } from "pages/MapView/MapView";
import { DashboardTopbar } from "templates/Header/DashboardTopbar";
import { ItinerarySidebar } from "templates/Sidebar/ItinerarySidebar";
import useVisibleState from "hooks/useVisibleStates";
import { DateTimeModal } from "molecules/DateTimeModal";
import "../App.css";
import { ShareModal } from "molecules/ShareModal";
import { ChangeDestinationModal } from "molecules/ChangeDestinationModal";
import { getTripData, writeTripData } from "firebaseDB";
import firebaseConfig from "firebaseConfig";
import { AppProvider } from "context/AppContext";
import TripService from "../services/TripService";
import { DeleteItineraryModal } from "molecules/DeleteItineraryModal";

const TripItinerary = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const tripId = parseInt(params.get("tripId"), 10);
  const tripUrl = params.get("tripUrl");

  const itineraryParam = useMemo(
    () => querystring.parse(window.location.search.slice(1) || "{}")?.tripUrl,
    []
  );

  const isMobile = useMemo(() => {
    const root = document.getElementById("root");
    return root.clientWidth < 600;
  }, []);

  window.isMobile = isMobile;

  const [itineraryName, setItinerayName] = useState("");
  const [isDone, setDone] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { visible, show, hide } = useVisibleState(false);
  const [isDashboard, setDashboard] = useState(true);
  const [itineraryToDelete, setItineraryToDelete] = useState();

  const { visible: share, show: ss, hide: hh } = useVisibleState(false);

  const {
    visible: destinationVisible,
    show: showDestination,
    hide: hideDestination,
  } = useVisibleState(false);

  const {
    visible: deletemodal,
    show: deleteModalOpen,
    hide: deleteModalClose,
  } = useVisibleState(false);

  const [destination, setDestination] = useState();
  const [index, setIndex] = useState(1);

  useEffect(() => {
    const getMarkers = async () => {
      try {
        const data = await getTrips(itineraryParam);
      } catch (e) {
        console.log(e);
      }
    };

    if (itineraryParam) {
      //id = itineraryParam;
      getMarkers();
    }
  }, [itineraryParam]);

  const [markers, setMarkers] = useState([]);

  const addMarker = () => {
    let lat;
    let long;

    if (
      destination.latitude &&
      destination.longitude &&
      !destination.position
    ) {
      lat = destination.latitude;
      long = destination.longitude;
    } else {
      lat = destination.position.lat().toString();
      long = destination.position.lng().toString();
    }

    const tripPayload = {
      latitude: lat,
      longitude: long,
      location: destination.formatted_address,
      startDate: destination.startDate,
      endDate: destination.endDate,
      image: destination.image,
      placeId: destination.placeId,
      city: destination.city,
      state: destination.state,
      country: destination.country,
    };
    // if same destination is added again
    const sameDes = markers.filter(
      (m) => m.location === destination.formatted_address
    );

    // if old marker is being edited
    const oldMarker = markers.find((m) => m.id === destination.id);

    // open sidebar when add a location
    if (!isMobile) {
      setCollapsed(false);
    }

    if (sameDes.length > 1) {
      toast.error("cannot add same destination more than 2 times");
    } else {
      // check if last destination is the same city
      // should not go in same city in a row
      if (markers.slice(-1)?.[0]?.location === destination.formatted_address) {
        toast.error("previous and next destination cannot be same");
      } else {
        // new destination may or may not exist atmost 1 time
        const updateTripPayload = {
          ...tripPayload,
          tripId: tripId,
          tripIndex: index,
        };
        addItineraryToTrip(updateTripPayload);
        //setMarkers((m) => [...m, destination]);
      }
    }
    // clear from modal when added to list
    setDestination();
    hide();
  };

  useEffect(() => {
    if (destination) {
      show();
    }
  }, [destination, show]);

  const onDeleteItinerary = () => {
    deleteItinerary(itineraryToDelete.trip, itineraryToDelete.id);
  };

  const generateItinerary = (name) => {
    setItinerayName(name);
    setCollapsed(true);
    //showDestination();
  };

  const onDone = () => {
    setDone(true);
    //id = uuid().replace(/-/g, "");
    setCollapsed(true);
    //writeTripData(id, JSON.stringify({ itineraryName, markers }));
  };

  const onEdit = useCallback((des) => {
    setDestination({ ...des });
  }, []);

  const onClear = useCallback((des) => {
    deleteItinerary(tripId, des);
  }, []);

  const getTrips = (tripUrl) => {
    TripService.getTripDetailsByUrl(tripUrl)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          setItinerayName(response.data?.data.trip.name);
          setMarkers(response.data?.data.itineraries);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        toast.error(response.message);
      });
  };

  const addItineraryToTrip = (tripData) => {
    TripService.addItineraryToTrip(JSON.stringify(tripData))
      .then((response) => {
        if (response.status === 201 && response.data.status === "SUCCESS") {
          setMarkers((m) => [...m, response.data.data.result]);
          setIndex(index + 1);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        toast.error(response.data.message);
      });
  };

  const deleteItinerary = (tripId, itineraryId) => {
    TripService.deleteItinerary(tripId, itineraryId)
      .then((response) => {
        if (response.status === 200 && response.data.status === "SUCCESS") {
          toast.success("Deleted Successfully");
          deleteModalClose();
          getTrips(tripUrl);
          //setMarkers((prev) => prev.filter((p) => p.id !== id));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        toast.error(response.message);
      });
  };

  return (
    <Div100vh className="app" style={{ backgroundColor: "white" }}>
      <ToastContainer />
      <DashboardTopbar isDashboard={isDashboard} />
      <div className="itinerary-strip">
        <div className="itinerary-name">{itineraryName}</div>
      </div>
      <div className="main-wrapper">
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        {/* <MapGlobeSwitch isGlobe={isGlobe} setGlobe={setGlobe} /> */}
        {destinationVisible && (
          <ChangeDestinationModal
            visible
            title="Where to?"
            onClose={hideDestination}
            changeDestination={(des) => {
              setDestination(des);
              hideDestination();
            }}
          />
        )}

        {visible && (
          <DateTimeModal
            visible
            isEdit={!!markers.find((m) => m.id === destination.id)}
            onClose={() => {
              setDestination();
              hide();
            }}
            destination={destination}
            setMarker={addMarker}
            setDestination={setDestination}
            lastEndDate={
              markers.findIndex((m) => m.id === destination.id) + 1
                ? markers[markers.findIndex((m) => m.id === destination.id) - 1]
                    ?.end_date
                : markers.slice(-1)?.[0]?.endDate
            }
            nextStartDate={
              markers.find((m) => m.id === destination.id) &&
              markers[markers.findIndex((m) => m.id === destination.id) + 1]
                ?.startDate
            }
          />
        )}

        {deletemodal && (
          <DeleteItineraryModal
            visible
            onClose={deleteModalClose}
            onClear={onDeleteItinerary}
          />
        )}

        <ItinerarySidebar
          tripUrl={tripUrl}
          destinations={markers}
          onEdit={onEdit}
          setItineraryToDelete={setItineraryToDelete}
          deleteModalOpen={deleteModalOpen}
          setNewDestination={showDestination}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          isDone={isDone}
          onGenerate={onDone}
        />

        <div>
          <MapView
            markers={markers}
            setItineraryToDelete={setItineraryToDelete}
            deleteModalOpen={deleteModalOpen}
            itineraryName={isDone}
            setMarkers={setMarkers}
            isMobile={isMobile}
            setMarker={addMarker}
            setDestination={setDestination}
          />
        </div>
      </div>
    </Div100vh>
  );
};

export default TripItinerary;
