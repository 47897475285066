import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import "./dashboardTopbar.css";
import MemoLogo from "assets/Logo";
import User from "assets/user.svg";
import Arrow from "assets/downArrow.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { LogoutModal } from "molecules/LogoutModal";
import useVisibleState from "hooks/useVisibleStates";
import Upload from "assets/upload.svg";
//import MemoAddIcon from "assets/icons/AddIcon";
//import Button from "atoms/Button";
//import MemoShareIcon from "assets/icons/ShareIcon";

export const DashboardTopbar = ({ isDashboard, isProfile, showShareModal }) => {
  const {
    visible: logoutmodal,
    show: logoutModalOpen,
    hide: logoutModalClose,
  } = useVisibleState(false);

  const avatar = window.sessionStorage.getItem("avatar");
  const name = window.sessionStorage.getItem("name");

  const UserToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="user-dropdown"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="user-logo">
        {avatar && avatar !== 'null' ? (
          <img
            src={avatar}
            alt="user"
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "50%",
            }}
          />
        ) : (
          <img
            src={User}
            alt="user"
            style={{
              height: "18px",
              width: "18px",
            }}
          />
        )}
      </div>

      <div className="heading">{children}</div>
      <img src={Arrow} alt="down arrow" className="arrow" />
    </div>
  ));

  const UserMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="header-dropdown">{children}</ul>
        </div>
      );
    }
  );

  return (
    <div className="topbar">
      {logoutmodal && <LogoutModal visible onClose={logoutModalClose} />}
      <div className="d-flex align-center">
        <a
          href={window.location.protocol + "//" + window.location.hostname}
          style={{ height: "2.2rem" }}
        >
          <MemoLogo fill="#F15BB5" width="11rem" height="2.2rem" />
        </a>
      </div>

      {isDashboard && (
        <Dropdown>
          <Dropdown.Toggle as={UserToggle}>
            {name && name !== 'null' ? name : "Welcome! User"}
          </Dropdown.Toggle>

          <Dropdown.Menu as={UserMenu}>
            <Dropdown.Item eventKey="1">
              <Link
                to="/dashboard"
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                Dashboard
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <Link
                to="/profile"
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                User Profile
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              onClick={logoutModalOpen}
              className="logout"
            >
              <div className="d-flex">
                Logout <img src={Upload} alt="upload" className="logout-logo" />
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};
