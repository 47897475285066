import * as React from "react";

function CopyIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M12.792.664l-.004-.003a.358.358 0 00-.26-.11H7.373c-1.001.001-1.812.812-1.813 1.813v.758h-2.41c-1 0-1.812.81-1.812 1.812v10.68c0 1.001.812 1.812 1.812 1.813h7.376c1 0 1.812-.812 1.812-1.812v-.758h2.41c1 0 1.812-.811 1.812-1.812V4.579a.374.374 0 00-.105-.258L12.792.664zm.098 1.124l2.439 2.435h-1.352a1.09 1.09 0 01-1.087-1.087V1.788zm-2.363 14.914H3.15a1.09 1.09 0 01-1.087-1.087V4.934A1.09 1.09 0 013.15 3.846h2.646a.382.382 0 00.254 0h1.895v1.86c0 1 .812 1.811 1.812 1.812h1.86v8.097c-.004.6-.49 1.085-1.091 1.087zm.583-9.91H9.758a1.09 1.09 0 01-1.087-1.086V4.357l2.44 2.436zm3.64 7.34h-2.411V7.148a.374.374 0 00-.105-.257l-3.66-3.657-.004-.004a.358.358 0 00-.261-.108H6.286v-.758a1.09 1.09 0 011.088-1.087h4.795v1.859c0 1 .811 1.812 1.812 1.812h1.855v8.097a1.09 1.09 0 01-1.087 1.087z"
        fill="#fff"
      />
      <path
        d="M3.535 9.718h3.672a.362.362 0 000-.725H3.535a.362.362 0 000 .725zM10.143 11.563H3.535a.363.363 0 000 .724h6.607a.362.362 0 100-.725zM10.143 14.132H3.535a.363.363 0 000 .725h6.607a.362.362 0 100-.725zM14.365 8.993h-1.106a.362.362 0 100 .725h1.106a.363.363 0 000-.725zM14.365 11.563h-1.106a.362.362 0 000 .724h1.106a.362.362 0 100-.725z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoCopyIcon = React.memo(CopyIcon);
export default MemoCopyIcon;
