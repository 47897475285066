import http from "../http-common";

const addTrip = (data) => {
  return http.post("/v1/trips", data);
};

const addItineraryToTrip = (data) => {
  return http.post("/v1/trips/itineraries", data);
};

const updateItinerary = (id, data) => {
  return http.patch(`/v1/trips/itineraries/${id}`, data);
};

const getTripDetailsByUrl = (tripUrl) => {
  return http.get(`/v1/trips/details?tripUrl=${tripUrl}`);
};

const getSearchedTrips = (limit, skip, sortBy, search) => {
  return http.get(
    `/v1/trips?limit=${limit}&skip=${skip}&sortBy=${sortBy}&search=${search}`
  );
};

const getFilteredTrips = (limit, skip, sortBy, category) => {
  return http.get(
    `/v1/trips?limit=${limit}&skip=${skip}&sortBy=${sortBy}&category=${category}`
  );
};

const getAllCategories = (limit, skip, sortBy) => {
  return http.get(
    `/v1/categories?limit=${limit}&skip=${skip}&sortBy=${sortBy}`
  );
};

const getTrips = (limit, skip, sortBy) => {
  return http.get(`/v1/trips?limit=${limit}&skip=${skip}&sortBy=${sortBy}`);
};

const deleteTrip = (tripId) => {
  return http.delete(`/v1/trips/${tripId}`);
};

const assignTrip = (tripId, userId) => {
  const data = {
    tripId: tripId,
    userId: userId,
  };
  return http.patch("/v1/trips/users", data);
};

const getItineraryById = (itineraryId) => {
  return http.get(`/v1/trips/itineraries/${itineraryId}`);
};

const deleteItinerary = (tripId, itineraryId) => {
  return http.delete(
    `/v1/trips/itineraries?tripId=${tripId}&itineraryId=${itineraryId}`
  );
};

export default {
  addTrip,
  addItineraryToTrip,
  updateItinerary,
  getTrips,
  assignTrip,
  getTripDetailsByUrl,
  deleteTrip,
  getSearchedTrips,
  getFilteredTrips,
  getItineraryById,
  deleteItinerary,
  getAllCategories,
};
