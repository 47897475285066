import * as React from "react";

function SidebarPlane(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" {...props}>
      <path d="M25.21 8.057L15.167 18.1v4.453c0 .703-.156 1.412-.464 2.072l-.228.434A1.61 1.61 0 0113 26c-.64 0-1.205-.36-1.463-.918l-.252-.482a4.894 4.894 0 01-.452-2.048V18.1L.793 8.059A2.688 2.688 0 010 6.145v-1.27a.54.54 0 01.834-.454l10.21 6.541c.1-1.639.237-3.007.404-4.672l.172-1.706L7.827 2.08a.543.543 0 01-.244-.453V.543a.544.544 0 01.659-.53L13 1.071 17.758.013a.537.537 0 01.456.106c.128.104.203.26.203.424v1.083a.539.539 0 01-.244.452L14.38 4.58l.172 1.711c.167 1.663.304 3.03.405 4.67l10.209-6.54a.54.54 0 01.551-.02.539.539 0 01.283.475v1.27c0 .723-.28 1.402-.79 1.911z" />
    </svg>
  );
}

const MemoSidebarPlane = React.memo(SidebarPlane);
export default MemoSidebarPlane;
