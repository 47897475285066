import * as React from "react";

function email(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)" fill="#fff">
        <path d="M12.77 1.668H1.23c-.189 0-.366.047-.527.123l6.27 6.27 1.405-1.35V6.71l4.919-4.919a1.216 1.216 0 00-.527-.123zM13.877 2.371L9.248 7l4.629 4.628c.076-.16.123-.337.123-.527V2.898c0-.19-.046-.367-.123-.527zM.123 2.371C.047 2.531 0 2.71 0 2.898v8.203c0 .19.047.367.123.527L4.752 7 .123 2.37z" />
        <path d="M8.668 7.58L7.263 8.93a.409.409 0 01-.58 0L5.332 7.58.703 12.21c.16.077.338.123.527.123h11.54c.189 0 .366-.046.527-.123L8.668 7.58z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const Memoemail = React.memo(email);
export default Memoemail;
