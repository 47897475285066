import * as React from "react";

function Linkedin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M2.167.762C1.136.762.46 1.439.46 2.329c0 .87.655 1.567 1.666 1.567h.02c1.051 0 1.705-.697 1.705-1.567-.02-.89-.654-1.567-1.685-1.567zM.64 5.134h3.014v9.07H.64v-9.07zM11.053 4.92c-1.626 0-2.716 1.529-2.716 1.529V5.134H5.322v9.07h3.014V9.137c0-.27.02-.541.1-.735C8.654 7.86 9.15 7.3 9.982 7.3c1.091 0 1.528.832 1.528 2.051v4.852h3.014v-5.2c0-2.786-1.487-4.082-3.47-4.082z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoLinkedin = React.memo(Linkedin);
export default MemoLinkedin;
