import * as React from "react";

function CloseIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M9.31 7l4.331-4.33a1.225 1.225 0 000-1.733L13.064.36a1.224 1.224 0 00-1.733 0l-4.33 4.33L2.668.36a1.224 1.224 0 00-1.732 0L.36.936a1.225 1.225 0 000 1.733L4.69 7 .36 11.331a1.224 1.224 0 000 1.733l.577.577a1.225 1.225 0 001.732 0L7 9.31l4.331 4.331a1.225 1.225 0 001.733 0l.577-.577a1.225 1.225 0 000-1.733L9.31 7.001z"
        fill="#2C2C2C"
      />
    </svg>
  );
}

const MemoCloseIcon = React.memo(CloseIcon);
export default MemoCloseIcon;
