import React from "react";
import Loader from "react-loader-spinner";

export const LoaderSpinner = () => (
  <Loader
    type="ThreeDots"
    color="#f15bb5"
    height={50}
    width={50}
    timeout={5000} 
    className="loader"
  />
);
