import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AuthGate from "pages/Auth";
import { AppProvider } from "context/AppContext";

import "./App.css";
import { assignAuthToken } from "http-common";

function App() {
  assignAuthToken(window.sessionStorage.getItem("authToken"));
  return (
    <AppProvider>
      <Router>
        <ToastContainer />
        <AuthGate />
      </Router>
    </AppProvider>
  );
}

export default App;
