import * as React from "react";

function facebook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M9.998 2.49h1.37V.107A17.684 17.684 0 009.373 0C7.398 0 6.046 1.242 6.046 3.524v2.101h-2.18v2.666h2.18V15h2.67V8.292h2.092l.331-2.666H8.716V3.789c0-.77.208-1.298 1.282-1.298z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const Memofacebook = React.memo(facebook);
export default Memofacebook;
