import * as React from "react";

function CalendarIcon(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 512 512"
      width="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M497 46.473H385.462V27.009c0-8.284-6.716-15-15-15s-15 6.716-15 15v19.464H156.538V27.009c0-8.284-6.716-15-15-15s-15 6.716-15 15v19.464H15c-8.284 0-15 6.716-15 15V484.99c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15V61.473c0-8.284-6.716-15-15-15zm-467 30h96.538v19.956c0 8.284 6.716 15 15 15s15-6.716 15-15V76.473h198.925v19.956c0 8.284 6.716 15 15 15s15-6.716 15-15V76.473H482v77.78H30zM482 469.99H30V184.253h452z" />
      <path d="M97.222 310.795h55.817c8.284 0 15-6.716 15-15v-55.816c0-8.284-6.716-15-15-15H97.222c-8.284 0-15 6.716-15 15v55.816c0 8.284 6.716 15 15 15zm15-55.816h25.817v25.816h-25.817zM228.092 310.795h55.817c8.284 0 15-6.716 15-15v-55.816c0-8.284-6.716-15-15-15h-55.817c-8.284 0-15 6.716-15 15v55.816c0 8.284 6.716 15 15 15zm15-55.816h25.817v25.816h-25.817zM358.961 310.795h55.816c8.284 0 15-6.716 15-15v-55.816c0-8.284-6.716-15-15-15h-55.816c-8.284 0-15 6.716-15 15v55.816c0 8.284 6.716 15 15 15zm15-55.816h25.816v25.816h-25.816zM97.222 429.265h55.817c8.284 0 15-6.716 15-15v-55.816c0-8.284-6.716-15-15-15H97.222c-8.284 0-15 6.716-15 15v55.816c0 8.284 6.716 15 15 15zm15-55.817h25.817v25.816h-25.817zM228.092 429.265h55.817c8.284 0 15-6.716 15-15v-55.816c0-8.284-6.716-15-15-15h-55.817c-8.284 0-15 6.716-15 15v55.816c0 8.284 6.716 15 15 15zm15-55.817h25.817v25.816h-25.817zM358.961 429.265h55.816c8.284 0 15-6.716 15-15v-55.816c0-8.284-6.716-15-15-15h-55.816c-8.284 0-15 6.716-15 15v55.816c0 8.284 6.716 15 15 15zm15-55.817h25.816v25.816h-25.816z" />
    </svg>
  );
}

const MemoCalendarIcon = React.memo(CalendarIcon);
export default MemoCalendarIcon;
