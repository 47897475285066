import * as React from "react";

function SearchIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M13.475 11.555a7.323 7.323 0 10-2.253 2.074l3.763 3.73a1.532 1.532 0 102.167-2.166l-3.678-3.638zm-5.982.5a4.723 4.723 0 11-.009-9.446 4.723 4.723 0 01.013 9.446h-.004z"
        fill="#000"
      />
    </svg>
  );
}

const MemoSearchIcon = React.memo(SearchIcon);
export default MemoSearchIcon;
