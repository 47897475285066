import React, { useMemo, useRef } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";

import Modal from "atoms/Modal";
import Button from "atoms/Button";
import dateFnsParse from "date-fns/parse";
import dateFnsFormat from "date-fns/format";
import { DateUtils } from "react-day-picker";
import MemoCalendarIcon from "assets/icons/CalendarIcon";
import DefaultDestination from "assets/DefaultDestination.png";
import Itinery from "assets/itenary.png";

import "./DateTimeModal.css";
import "react-day-picker/lib/style.css";
import { Searchbar } from "templates/Searchbar/Searchbar";
import { addMonths, isLastDayOfMonth } from "date-fns";

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = "dd-MMM, yyyy";

const DateTimeHeader = ({ destination, setDestination }) => (
  <div className="searchbar-container width-100 px-4">
    <Searchbar
      initialValue={destination.location}
      setDestination={setDestination}
    />
  </div>
);

export const DateTimeModal = ({
  destination,
  setMarker,
  setDestination,
  lastEndDate = new Date(),
  nextStartDate,
  isEdit,
  ...props
}) => {
  // const from = useMemo(() => destination.startDate, [destination.startDate]);
  // const to = useMemo(() => destination.endDate, [destination.endDate]);
  // const modifiers = { start: from, end: to };

  const from = useMemo(
    () => (destination?.startDate ? new Date(destination?.startDate) : null),
    [destination]
  );

  const to = useMemo(
    () => (destination?.endDate ? new Date(destination?.endDate) : null),
    [destination]
  );
  const modifiers = { start: from, end: to };

  const ref = useRef(null);

  return (
    <Modal
      Header={() => (
        <DateTimeHeader
          destination={destination}
          setDestination={(des) =>
            setDestination({
              ...des,
              id: destination.id,
              startDate: destination.startDate,
              endDate: destination.endDate,
            })
          }
        />
      )}
      className="datetime-modal"
      {...props}
    >
      <div className="datetime-modal-header">
        <img
          src={destination?.image || Itinery}
          alt="city images"
          className="datetime-modal-city-img"
        />
        <div className="d-flex align-center">
          <h2 className="datetime-modal-city-name">{destination?.location}</h2>
        </div>
      </div>
      <div className="datetime-modal-body">
        <p className="text-md font-500 mt-4 mb-1">
          Specify the start and end dates
        </p>
        <div className="d-flex justify-between">
          <label className="d-flex align-center date-wrapper">
            <div className="text-primary" style={{ height: "16px" }}>
              <MemoCalendarIcon />
            </div>
            <DayPickerInput
              value={from}
              placeholder="Start Date"
              format={FORMAT}
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { before: new Date(lastEndDate), after: to },
                fromMonth: from,
                modifiers,
                month: from || new Date(lastEndDate),
              }}
              onDayPickerHide={() => ref.current?.focus()}
              onDayChange={(day) =>
                setDestination({ ...destination, startDate: day })
              }
            />
          </label>
          <label className="d-flex align-center date-wrapper ml-3" ref={ref}>
            <div className="text-primary" style={{ height: "16px" }}>
              <MemoCalendarIcon />
            </div>
            <DayPickerInput
              value={to}
              placeholder="End Date"
              format={FORMAT}
              formatDate={formatDate}
              parseDate={parseDate}
              inputProps={{
                className: "datetime-modal-end-date",
              }}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: {
                  before: from || new Date(),
                  after: nextStartDate,
                },
                modifiers,
                month: isLastDayOfMonth(from) ? addMonths(from, 1) : from,
                fromMonth: from,
              }}
              onDayChange={(day) =>
                setDestination({ ...destination, endDate: day })
              }
              classNames={{
                container: "DayPickerInput",
                overlayWrapper: "DayPickerInput-OverlayWrapper",
                overlay: "DayPickerInput-Overlay editDatePicker",
              }}
            />
          </label>
        </div>
        <Button
          onClick={setMarker}
          disabled={!destination.startDate || !destination.endDate}
          variant={
            !destination.startDate || !destination.endDate
              ? "disabled"
              : undefined
          }
        >
          {isEdit ? "Update" : "Add"} City
        </Button>
      </div>
    </Modal>
  );
};
