import * as React from "react";

function LeftArrowIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 10" fill="none" {...props}>
      <path
        d="M11.325 4.06l.02.003h-8.03l2.524-2.53a.657.657 0 00.191-.466A.655.655 0 005.84.6L5.446.208a.651.651 0 00-.464-.191.651.651 0 00-.464.19L.191 4.536A.65.65 0 000 5a.65.65 0 00.191.465l4.327 4.327a.651.651 0 00.464.192c.176 0 .34-.068.464-.192l.393-.393a.65.65 0 000-.918L3.286 5.937h8.048A.68.68 0 0012 5.264v-.556c0-.362-.313-.649-.675-.649z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoLeftArrowIcon = React.memo(LeftArrowIcon);
export default MemoLeftArrowIcon;
